import Button from '@components/Button';
import RadioButton from '@components/RadioButton';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import EditIcon from '@images/pencil.svg';
import { numberWithCommas } from '../Order';
import { useStorage } from '../Provider/StorageProvider';
import { useToken } from '../Provider/TokenProvider';
import { endpoints, getUrl, headers, useApi } from '../hooks/api';
import { Feature, ItemTariff, ProductSetting, TariffCategory } from '../types';

type Props = {
  nextStep: () => void;
  previousStep: () => void;
  currentProduct: ItemTariff | undefined;
  kwh: number | undefined;
  product_id: number | undefined;
  tariffCategory: TariffCategory;
  currentProductFeatureProducts: ItemTariff[];
  isSideselling: boolean;
  product_setting: ProductSetting;
  setStep: Dispatch<SetStateAction<number>>;
  setActiveTariff: Dispatch<SetStateAction<number[]>>;
  setSelectedFeatures: Dispatch<SetStateAction<Record<string, any>>>;
  setEditedSidesell?: Dispatch<SetStateAction<boolean>>;
};

const ContractPeriod = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    nextStep,
    previousStep,
    currentProduct,
    product_id,
    tariffCategory,
    currentProductFeatureProducts,
    isSideselling,
    setStep,
    setActiveTariff,
    setSelectedFeatures,
    setEditedSidesell,
  } = props;
  const { storage, setStorage } = useStorage();
  const { deleteToken, token } = useToken();

  const savedFeatures: Record<string, any> = {};
  currentProduct?.features.forEach(item => {
    savedFeatures[item.name] = item.featureValue.value;
  });
  // overwrite selected for actual products
  currentProduct?.features.forEach(item => {
    currentProductFeatureProducts.forEach(featureProduct => {
      if (item.featureValue.product?.product_id === featureProduct.product_id) {
        savedFeatures[item.name] = item.featureValue.value;
      }
    });
  });

  const [currentSelectedFeatures, setCurrentSelectedFeatures] =
    React.useState<Record<string, any>>(savedFeatures);

  const updateUrl = token ? getUrl(token, endpoints.details) : '';
  const offerUrl = token ? getUrl(token, endpoints.offers) : '';

  const kwh = useMemo(() => {
    if (props.kwh) return props.kwh;
    if (currentProduct?.consumption) return currentProduct?.consumption;
  }, [currentProduct?.consumption, props.kwh]);

  const submitForm = () => {
    const queryString = new URLSearchParams({
      ...currentSelectedFeatures,
      consumption: props.kwh?.toString() ?? '',
    }).toString();

    const getExtraProducts = (offer: ItemTariff) => {
      const products: Object[] = [];
      offer.features.forEach(feature => {
        if (
          currentSelectedFeatures?.[feature.name] === feature.featureValue.value &&
          feature.featureValue.product
        ) {
          products.push({
            product_id: feature.featureValue.product.product_id,
            consumption: kwh,
            properties: { belongs_to: offer.product_id },
          });
        }
      });
      return products;
    };

    fetch(`${offerUrl}/${tariffCategory}/?${queryString}`, {
      method: 'GET',
      headers,
    }).then(async res => {
      const offers: any[] = await res.json();

      fetch(updateUrl, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({
          checkout: {
            items: [
              { product_id: offers[0]?.product_id, consumption: kwh },
              ...getExtraProducts(offers[0]),
            ],
          },
        }),
      }).then(padchedRes => {
        padchedRes.json().then(json => {
          if (storage) {
            setStorage({ ...storage, ...json });
          }
        });

        nextStep();
      });
    });
  };

  const { data } = useApi<Feature[]>(
    `${endpoints.features}/${tariffCategory}/?consumption=${kwh}`,
    undefined,
    undefined,
    !!kwh,
  );

  // wenn eine Produkt Id vorhanden ist, soll nur das eine Produkt mit seinen features angezeigt werden.
  const { data: definedProduct } = useApi<Feature>(
    `${endpoints.products}/${product_id}/?consumption=${kwh}`,
    undefined,
    undefined,
    !!product_id,
  );
  const { data: offers } = useApi<ItemTariff[]>(
    `${endpoints.offers}/${tariffCategory}/?consumption=${kwh}`,
  );
  const tariffByOptions = useMemo(() => {
    const match: any = {};
    data?.forEach(feature => {
      match[feature.name] = {};
      feature.choices.forEach(choice => {
        match[feature.name][choice.value] = [];
      });
    });
    offers?.forEach(offer => {
      offer.features.forEach(feature => {
        if (match[feature.name]) {
          match[feature.name][feature.featureValue.value].push(offer.product_id);
        }
      });
    });
    return match;
  }, [offers, data]);

  const activeTariff = useMemo(() => {
    const actives: number[] = [];
    offers?.forEach(order => {
      const id = order.product_id;
      const checks: boolean[] = [];
      Object.entries(currentSelectedFeatures).forEach(([key, value]) => {
        checks.push(tariffByOptions[key]?.[value]?.includes(id));
      });
      if (checks.every(item => item === true)) {
        actives.push(id);
      }
    });
    return actives;
  }, [tariffByOptions, offers, currentSelectedFeatures]);

  useEffect(() => {
    setActiveTariff(activeTariff);
  }, [activeTariff, setActiveTariff]);

  const setFeature = React.useCallback(
    (slug: string, value: string) => {
      if (tariffByOptions[slug][value].some((id: number) => activeTariff.includes(id))) {
        const new_data = { ...currentSelectedFeatures };
        new_data[slug] = value;
        setCurrentSelectedFeatures(new_data);
        setSelectedFeatures(new_data);
      } else {
        const new_data: any = {};
        new_data[slug] = value;
        setCurrentSelectedFeatures(new_data);
        setSelectedFeatures(new_data);
      }
    },
    [tariffByOptions, activeTariff, currentSelectedFeatures, setSelectedFeatures],
  );

  const hasAllCompletedOrders = useMemo(() => {
    return storage?.items?.every(
      obj => !!obj?.consumption_point?.meter_number || obj.properties?.belongs_to,
    );
  }, [storage?.items]);

  // remove sideselling product then go back to first page
  const sidesellingReturnToKwh = React.useCallback(() => {
    const currentProductId = currentProduct?.product_id;
    const allIds = [currentProductId];
    const belongingProducts = storage?.items.filter(item => {
      if (item.properties && item.properties.belongs_to === currentProductId) return item;
      return null;
    });
    const belongingIds = belongingProducts?.map(belItem => {
      return belItem.product_id;
    });
    if (belongingIds) {
      allIds.push(...belongingIds);
    }

    if (!currentProductId) {
      return;
    }

    if (token) {
      fetch(getUrl(token, endpoints.checkoutDelete), {
        method: 'POST',
        body: JSON.stringify({ products: [...allIds] }),
        headers,
      }).then(res => {
        res.json().then(newdata => {
          setStorage({ ...storage, ...newdata });
          if (setEditedSidesell) {
            setEditedSidesell(true);
          }
          setStep(0);
        });
      });
    }
  }, [currentProduct?.product_id, storage, token, setStorage, setEditedSidesell, setStep]);

  return (
    <div className='ContractPeriod' ref={ref}>
      <h2 className='Order__subtitle'>
        Vertragslaufzeit - Ihr {props.product_setting.name}vertrag
      </h2>

      {/* <form> */}
      <div>
        bei {numberWithCommas(props.kwh || currentProduct?.consumption || 0, 0)} kWh{' '}
        <button
          className='order__edit-kwh-button'
          onClick={() => {
            if (!isSideselling) {
              deleteToken();
            } else if (isSideselling && hasAllCompletedOrders && !currentProduct) {
              setStep(0);
            } else if (isSideselling && (!hasAllCompletedOrders || currentProduct)) {
              sidesellingReturnToKwh();
            }
          }}
        >
          <EditIcon className='order__edit-kwh-svg' />
        </button>
      </div>
      {product_id && definedProduct ? (
        <div />
      ) : (
        data?.map(feature => {
          if (feature.name !== 'oekostrom') {
            return (
              <div className='feature-container' key={feature.name}>
                <h2>{feature.label}</h2>
                {feature.choices.map(item => (
                  <div
                    key={item.label}
                    className={`option ${
                      tariffByOptions[feature.name][item.value].some((id: number) =>
                        activeTariff.includes(id),
                      )
                        ? 'active'
                        : 'inactive'
                    }`}
                    role='button'
                    tabIndex={0}
                    onKeyDown={() => setFeature(feature.name, item.value)}
                    onClick={() => setFeature(feature.name, item.value)}
                  >
                    <div className='option__radio'>
                      <RadioButton checked={currentSelectedFeatures[feature.name] === item.value} />
                    </div>
                    <div className='wrapper'>
                      <div className='option__label'>
                        <strong>{item.label}</strong>
                      </div>
                      {item.product ? (
                        <div className='option__price'>
                          {item.product.calculated_price_brutto.amount !== '0,00'
                            ? `${item.product.calculated_price_brutto?.amount} ${item.product.calculated_price_brutto?.currency} / Monat`
                            : null}
                        </div>
                      ) : (
                        <div className='option__price'>
                          {item.min_price?.amount} {item.min_price?.currency} / Monat
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          }
          return null;
        })
      )}

      <p className='navigation-row'>
        <Button className='prev' onClick={() => previousStep()}>
          Zurück
        </Button>
        <Button
          className='next'
          primary
          disabled={
            data?.filter(feat => feat.name !== 'oekostrom') &&
            Object.keys(currentSelectedFeatures).filter(elem => elem !== 'oekostrom').length <
              data?.filter(feat => feat.name !== 'oekostrom').length
          }
          onClick={() => {
            if (tariffCategory === 'electric') {
              nextStep();
            } else {
              submitForm();
            }
          }}
        >
          Weiter
        </Button>
      </p>
      {/* </form> */}
    </div>
  );
});

ContractPeriod.displayName = 'ContractPeriod';

export default ContractPeriod;
