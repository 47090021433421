/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Image from 'next/image';
import { WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';

type Props = {
  title?: string;
  iconimage: WagtailStreamfieldImage | null;
  iconsvg?: string;
  table: {
    data: string[][];
    first_row_is_table_header: boolean;
    first_col_is_header: boolean;
    table_caption?: string;
  };
};

function Table(props: Props) {
  const {
    title,
    iconimage,
    iconsvg,
    table: { data, first_row_is_table_header, first_col_is_header, table_caption },
  } = props;

  return (
    <div className='streamfield table'>
      {title ? <h2 className='table__title'>{title}</h2> : null}
      <div className='bg-wrapper'>
        {iconimage ? (
          <div className='table__icon'>
            <Image
              src={addBaseUrl(iconimage.file.src)}
              alt={iconimage.file.alt}
              width={78}
              height={78}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </div>
        ) : null}
        {iconsvg ? (
          <div className='table__icon'>
            <RawHtml className='table__icon' html={iconsvg} />{' '}
          </div>
        ) : null}
        <table className='table__element'>
          {table_caption ? <caption className='table__caption'>{table_caption}</caption> : null}
          {first_row_is_table_header ? (
            <thead className='table__header'>
              <tr className='table__row'>
                {data[0].map((th, index) => (
                  <th
                    className='table__head'
                    key={`${th}${index}`}
                    dangerouslySetInnerHTML={{ __html: th ?? '' }}
                  />
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody className='table__body'>
            {data.map((row, index) => {
              if (first_row_is_table_header && index === 0) {
                return null;
              }
              return (
                <tr className='table__row' key={index}>
                  {row.map((td, index) =>
                    first_col_is_header && index === 0 ? (
                      <th
                        className='table__head'
                        key={`${td}${index}`}
                        dangerouslySetInnerHTML={{ __html: td ?? '' }}
                      />
                    ) : (
                      <td
                        className='table__data'
                        key={`${td}${index}`}
                        dangerouslySetInnerHTML={{ __html: td ?? '' }}
                      />
                    ),
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
