import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import Label from '../Label';

type SelectItem = {
  value: string;
  text: string;
  disabled?: boolean;
};

type Props = {
  items: SelectItem[];
  ariaLabel: string;
  label?: string;
  id?: string;
  name?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  fluid?: boolean;
  className?: string;
};

function Select(props: Props) {
  const { items, id, name, defaultValue, ariaLabel, onValueChange, fluid, label } = props;

  return (
    <div className={clsx('select', { 'select--fluid': fluid }, { input: props.className })}>
      {label ? <Label htmlFor={name ?? id} text={label} /> : null}
      <SelectPrimitive.Root
        defaultValue={defaultValue || items[0]?.value}
        onValueChange={onValueChange}
        name={name ?? id}
      >
        <SelectPrimitive.Trigger className='select__trigger' aria-label={ariaLabel} id={name ?? id}>
          <SelectPrimitive.Value className='select__value' />
          <SelectPrimitive.Icon className='select__icon'>
            <ChevronDownIcon width={32} height={32} />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Content className='select__content'>
          <SelectPrimitive.ScrollUpButton>
            <ChevronUpIcon />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport className='select__viewport'>
            {items.map(item => (
              <SelectPrimitive.Item
                key={item.value}
                className='select__item'
                value={item.value}
                disabled={item.disabled}
              >
                <SelectPrimitive.ItemIndicator className='select__item-indicator'>
                  <CheckIcon width={32} height={32} />
                </SelectPrimitive.ItemIndicator>
                <SelectPrimitive.ItemText className='select__item-text'>
                  {item.text}
                </SelectPrimitive.ItemText>
              </SelectPrimitive.Item>
            ))}
          </SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton>
            <ChevronDownIcon />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Root>
    </div>
  );
}

export default Select;
