/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { WagtailVideo } from '@/types';

type Props = {} & WagtailVideo;

function Video(props: Props) {
  const { file, thumbnail } = props;

  return (
    <div className='streamfield video'>
      <video preload='metadata' width='100%' controls poster={thumbnail ?? ''}>
        <source src={file} type={`video/${file.match(/\.([\w\d]+)$/)?.[1] ?? 'mp4'}`} />
      </video>
    </div>
  );
}

export default Video;
