import RawHtml from '@components/RawHtml';
import React from 'react';
import CheckmarkSvg from '@images/checkmark.svg';

type Props = {
  blocktitle: string;
  blocksubtitle: string;
  bulletpoints: { title: string; content: string }[];
};

function Bulletpoints(props: Props) {
  const { bulletpoints, blocktitle, blocksubtitle } = props;

  return (
    <div className='streamfield bulletpoints'>
      {blocktitle ? <h2 className='bulletpoints__block-title'>{blocktitle}</h2> : null}
      {blocksubtitle ? <div className='bulletpoints__block-subtitle'>{blocksubtitle}</div> : null}
      <div className='bulletpoints__container'>
        {bulletpoints.map(bulletpoint => (
          <div className='bulletpoint' key={bulletpoint.title}>
            <div>
              <CheckmarkSvg className='bulletpoint__svg' />
            </div>
            <div className='bullletpoint__content'>
              <h3 className='bulletpoint__headline'>{bulletpoint.title}</h3>
              <div className='bulletpoint__text'>
                <RawHtml className='richtext' html={bulletpoint.content} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Bulletpoints;
