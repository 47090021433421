import React from 'react';
// import AccordionsComponent from '@components/Accordions';
import Contact, { ContactType } from '@components/Contact/Contact';

type Group = {
  title: string;
  contacts: ContactType[];
};

type Props = {
  groups: Group[];
};

function ContactGroup(props: Props) {
  const { groups } = props;

  return (
    <div className='streamfield contact-groups'>
      {groups.map(group => (
        <div className='contact-groups__group-container' key={group.title}>
          <h2 className='contact-groups__group-title'>{group.title}</h2>
          <div className='contact-groups__group'>
            {group.contacts.map(contact => (
              <Contact key={contact?.id} contact={contact} />
            ))}
          </div>
        </div>
      ))}
    </div>

    // <AccordionsComponent
    //   accordions={groups.map(group => {
    //     return {
    //       title: group.title,
    //       text: (
    //         <div key={group.title}>
    //           {group.contacts.map(contact => (
    //             <Contact key={contact?.id} contact={contact} />
    //           ))}
    //         </div>
    //       ),
    //     };
    //   })}
    // />
    // {/* <AccordionsComponent accordions={[{ title, text: "test" }]} /> */}
    // </div>
  );
}

export default ContactGroup;
