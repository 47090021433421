import clsx from 'clsx';
import React from 'react';
import ProductOverviewComponent, {
  ProductOverviewType,
} from '@components/ProductOverview/ProductOverview';

type Props = {
  title: string;
  overview: ProductOverviewType[];
};

function ProductOverview(props: Props) {
  const { title, overview } = props;

  return (
    <div className={clsx('streamfield', 'product-overview')}>
      <h2 className='product-overview__title'>{title}</h2>
      <ProductOverviewComponent overview={overview} />
    </div>
  );
}

export default ProductOverview;
