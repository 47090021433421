import React, { PropsWithChildren, useState } from 'react';
import * as TooltipTemplate from '@radix-ui/react-tooltip';

type Props = {
  text: string;
};

function Tooltip(props: PropsWithChildren<Props>) {
  const { text, children } = props;
  const [open, setOpen] = useState(false);

  return (
    <TooltipTemplate.Provider>
      <TooltipTemplate.Root open={open} onOpenChange={setOpen}>
        <TooltipTemplate.Trigger asChild>
          <button
            className='IconButton'
            onClick={e => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            {children}
          </button>
        </TooltipTemplate.Trigger>
        <TooltipTemplate.Portal>
          <TooltipTemplate.Content className='TooltipContent' sideOffset={5}>
            {text}
            <TooltipTemplate.Arrow className='TooltipArrow' />
          </TooltipTemplate.Content>
        </TooltipTemplate.Portal>
      </TooltipTemplate.Root>
    </TooltipTemplate.Provider>
  );
}

export default Tooltip;
