import Accordions from '@components/Accordions/Accordions';
import Button from '@components/Button';
import Contact from '@components/Contact';
import React, { useCallback, useEffect } from 'react';
import { numberWithCommas } from '../Order';
import { ItemTariff, ProductSetting } from '../types';

type Props = {
  nextStep: () => void;
  currentProduct: ItemTariff | undefined;
  setKwh: React.Dispatch<React.SetStateAction<number | undefined>>;
  kwh: number | undefined;
  product_setting: ProductSetting;
};

const KwhCalculator = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [kwh, setKwh] = React.useState<number | undefined>(props.kwh);
  const [viewContact, setViewContact] = React.useState(
    (props.kwh && props.kwh >= props.product_setting.max_kwh) ?? false,
  );
  const [selectedKWH, setSelectedKWH] = React.useState<boolean>(false);

  useEffect(() => {
    setKwh(props.currentProduct?.consumption);
  }, [props.currentProduct?.consumption]);

  const submit = useCallback(() => {
    if (kwh && kwh >= props.product_setting.max_kwh) {
      setViewContact(true);
    } else {
      if (!kwh) return;
      if (kwh > 0) {
        props.setKwh(kwh);
        props.nextStep();
      }
    }
  }, [kwh, props]);

  // automatically submit kwh when clicking on select option
  useEffect(() => {
    if (selectedKWH === true) {
      submit();
      setSelectedKWH(false);
    }
  }, [selectedKWH, submit]);

  // 1c8a5a77-f919-4275-925c-3d59d0b626b3
  return (
    <div className='KwhCalculator' ref={ref}>
      <h2 className='Order__subtitle'>Wählen Sie Ihren {props.product_setting.name}vertrag</h2>

      <div>
        <form
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}
        >
          <div>
            <Accordions
              key={`accordion${viewContact}`}
              defaultOpen={!viewContact}
              accordions={[
                {
                  title: `${props.product_setting.name}verbrauch kWh / Jahr`,
                  text: (
                    <>
                      <div className='inputwrapper'>
                        <input
                          id='stromverbrauch'
                          required
                          placeholder='kWh / Jahr'
                          value={kwh || ''}
                          min={1}
                          type='number'
                          onChange={event => setKwh(parseInt(event.target.value, 10))}
                        />
                        <Button primary className='next' onClick={() => {}}>
                          <span className='sr-only'>Weiter</span>
                        </Button>
                      </div>
                      {props.product_setting.kwh.map(obj => {
                        return (
                          <div
                            tabIndex={0}
                            key={obj.id}
                            className='option'
                            aria-label='Vordefinierter Verbrauch'
                            onClick={() => {
                              setSelectedKWH(true);
                              setKwh(obj.kwh);
                            }}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                setSelectedKWH(true);
                                setKwh(obj.kwh);
                              }
                            }}
                            role='button'
                          >
                            {numberWithCommas(obj.kwh, 0)} kWh {obj.unit ? `(${obj.unit})` : null}
                          </div>
                        );
                      })}{' '}
                      {props.product_setting.max_kwh ? (
                        <div
                          tabIndex={0}
                          className='option'
                          aria-label='Vordefinierter Verbrauch'
                          onClick={() => {
                            setViewContact(true);
                          }}
                          onKeyUp={e => {
                            if (e.key === 'Enter') {
                              setViewContact(true);
                            }
                          }}
                          role='button'
                        >
                          &gt;={' '}
                          {new Intl.NumberFormat('de-DE', {}).format(props.product_setting.max_kwh)}{' '}
                          kWh
                        </div>
                      ) : null}
                    </>
                  ),
                },
              ]}
            />
          </div>

          {/* <div className='navigation-row'>
              <Button primary className='next' onClick={() => {}}>
                Weiter
              </Button>
            </div> */}
        </form>
      </div>
      {viewContact ? (
        <div className='business-customer'>
          {/* <div className='Order__subtitle'>Ansprechpartner</div> */}
          {/* <div>Kontaktieren Sie unsere Kundenbetreuer für eine individuelle Beratung.</div> */}
          <div>
            Liegt Ihr Verbrauch über{' '}
            {props.product_setting.max_kwh.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} kWh,
            sprechen Sie uns gern an.
          </div>
          <h2>Ihr Kontakt für Gewerbe</h2>
          {props.product_setting.contact_list.map(obj => (
            <Contact key={obj.id} contact={obj} />
          ))}
        </div>
      ) : null}
    </div>
  );
});

KwhCalculator.displayName = 'KwhCalculator';

export default KwhCalculator;
