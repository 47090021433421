import React, { useCallback, useEffect, useRef } from 'react';
import RawHtml from '@components/RawHtml';
import { WagtailSvg, WagtailPage } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';
import Link from 'next/link';
import { ProductSetting } from '@components/Order/types';
import Router from 'next/router';
import Button from '@components/Button';

export type ProductOptionType = {
  title: string;
  svg: WagtailSvg;
  page: WagtailPage;
};

export type ProductOptionIntro = {
  description: string;
  headline: string;
  product_settings: ProductSetting;
};

type Props = {
  options: ProductOptionType[];
  text: string;
  card: number;
  orderIntro?: ProductOptionIntro;
};

export function numberWithCommas(x: number, fixed: number | undefined = undefined) {
  const x_string = x.toFixed(fixed !== undefined ? fixed : 2);
  const parts = x_string.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}
function ProductOverview(props: Props) {
  const { options, orderIntro } = props;

  const [kwh, setKwh] = React.useState<number | undefined>(undefined);
  const [selectedKWH, setSelectedKWH] = React.useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);

  const submit = useCallback(() => {
    if (orderIntro?.product_settings.name === 'Strom') {
      Router.push({ pathname: '/tarifauswahl', query: { kwhConsumption: kwh } });
    } else {
      Router.push({ pathname: '/tarifauswahl-gas', query: { kwhConsumption: kwh } });
    }
  }, [kwh, orderIntro?.product_settings.name]);

  // automatically submit kwh when clicking on select option
  useEffect(() => {
    if (selectedKWH === true) {
      submit();
      setSelectedKWH(false);
    }
  }, [selectedKWH, submit]);

  return (
    <div className='product-option component'>
      <div className='product-option__links-container'>
        {options.map((option, index) => (
          <Link href={option.page.full_url} key={option.title} className='product-option__product'>
            <span className='product-option__svg'>
              <Image
                src={addBaseUrl(option.svg.file)}
                alt={option.svg.title}
                width={40}
                height={40}
              />
            </span>
            <span className='product-option__title'>{option.title}</span>
          </Link>
        ))}
      </div>

      {orderIntro ? (
        <div
          className='product-option__kwh'
          onFocusCapture={e => {
            optionsRef.current?.classList.add('product-overview__kwh-calc-content--active');
          }}
          onBlur={e => {
            const optionNodes: (ChildNode | null)[] = [];
            optionsRef.current?.childNodes.forEach(elem => optionNodes.push(elem));
            if (!optionNodes.includes(e.relatedTarget)) {
              optionsRef.current?.classList.remove('product-overview__kwh-calc-content--active');
            }
          }}
        >
          <div className='Order'>
            <div className='KwhCalculator kwh-calc--streamfield'>
              <div>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    submit();
                  }}
                >
                  <div
                    className={`product-overview__kwh-calc${
                      orderIntro.product_settings.product_name === 'gas'
                        ? ' product-overview__kwh-calc--gas'
                        : ''
                    }`}
                  >
                    <div className='product-overview__kwh-calc-text'>
                      <strong>{orderIntro.headline}</strong>
                      <RawHtml className='richtext' html={orderIntro.description} />
                    </div>
                    <div className='kwh-calc__content-container'>
                      <div className='inputwrapper'>
                        <input
                          id='stromverbrauch'
                          required
                          aria-label='Verbrauch in kWh / Jahr eingeben'
                          placeholder='Verbrauch in kWh / Jahr'
                          value={kwh || ''}
                          min={1}
                          type='number'
                          onChange={event => setKwh(parseInt(event.target.value, 10))}
                        />
                        <Button primary className='next' onClick={() => {}}>
                          <span className='sr-only'>Weiter</span>
                        </Button>
                      </div>
                      <div className='product-overview__kwh-calc-content' ref={optionsRef}>
                        {orderIntro.product_settings.kwh.map(obj => {
                          return (
                            <div
                              key={obj.id}
                              className='option'
                              tabIndex={0}
                              aria-label='Vordefinierter Verbrauch'
                              onClick={() => {
                                setSelectedKWH(true);
                                setKwh(obj.kwh);
                              }}
                              onKeyUp={e => {
                                if (e.key === 'Enter') {
                                  setSelectedKWH(true);
                                  setKwh(obj.kwh);
                                }
                              }}
                              role='button'
                            >
                              {numberWithCommas(obj.kwh, 0)} kWh {obj.unit ? `(${obj.unit})` : null}
                            </div>
                          );
                        })}{' '}
                        {orderIntro.product_settings.max_kwh ? (
                          <div
                            className='option'
                            tabIndex={0}
                            aria-label='Vordefinierter Verbrauch'
                            onClick={() => {
                              setSelectedKWH(true);
                              setKwh(orderIntro.product_settings.max_kwh);
                            }}
                            onKeyUp={e => {
                              if (e.key === 'Enter') {
                                setSelectedKWH(true);
                                setKwh(orderIntro.product_settings.max_kwh);
                              }
                            }}
                            role='button'
                          >
                            &gt;={' '}
                            {new Intl.NumberFormat('de-DE', {}).format(
                              orderIntro.product_settings.max_kwh,
                            )}{' '}
                            kWh
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className='navigation-row'>
              <Button primary className='next' onClick={() => {}}>
                Weiter
              </Button>
            </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`product-option__text card-${props.card}`}>
          <RawHtml className='richtext' html={props.text} />
        </div>
      )}
    </div>
  );
}

export default ProductOverview;
