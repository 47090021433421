import Link from 'next/link';
import React from 'react';
import Image from 'next/image';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailStreamfieldImage, WagtailSvg } from '@/types';

type LinkTile = {
  title: string;
  button_page: WagtailPage | null;
  button_url: string | null;
  image: WagtailStreamfieldImage | null;
  // svg: string | null;
  svgfile: WagtailSvg | null;
};

type Props = {
  link_kacheln: LinkTile[];
  title?: String;
};

function Links(props: Props) {
  const { link_kacheln, title } = props;

  return (
    <div className='streamfield linkswrapper'>
      {title ? <h2 className='tile'>{title}</h2> : null}
      <div className='links'>
        {link_kacheln.map(tile => (
          <Link
            key={tile.title}
            href={
              tile.button_page ? removeBaseUrl(tile.button_page.full_url) : tile.button_url ?? ''
            }
            passHref
            className='links__tile'
          >
            <div className='title'>{tile.title}</div>
            {tile.image ? (
              <Image
                src={addBaseUrl(tile.image.file.src)}
                alt={tile.image.file.alt}
                className='icon'
                width={78}
                height={78}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            ) : null}
            {tile.svgfile ? (
              <Image
                src={addBaseUrl(tile.svgfile.file)}
                alt={tile.svgfile.title}
                width={81}
                height={81}
              />
            ) : null}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Links;
