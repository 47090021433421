import { Checkbox, Input } from '@components/Form';
import Select from '@components/Form/Select';
import React, { useId, useReducer, useState } from 'react';
import Link from 'next/link';
import Button from '@components/Button';
import RawHtml from '@components/RawHtml';

const initialData = {
  anrede: '',
  vorname: '',
  nachname: '',
  unternehmen: '',
  email: '',
  datenschutz: false,
};

type State = typeof initialData;
type Status = 'idle' | 'loading' | 'success' | 'error';

type Props = {
  is_business: boolean;
  title?: string;
  text?: string;
};

function NewsletterRegister(props: Props) {
  const { is_business, title, text } = props;
  const id = useId();
  const [status, setState] = useState<Status>('idle');
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initialData,
  );

  return (
    <div className='streamfield newsletter-register dataForm'>
      {title ? <h2 className='cards__title'>{title}</h2> : null}
      {text ? <RawHtml html={text} /> : null}
      <form
        className='form'
        onSubmit={async e => {
          e.preventDefault();
          setState('loading');
          const res = await fetch('/api/newsletter/subscribe', {
            method: 'POST',
            body: JSON.stringify(state),
          });

          if (res.ok) {
            setState('success');
            return;
          }

          setState('error');
        }}
      >
        <Select
          ariaLabel='Anrede'
          label='Anrede'
          name='anrede'
          id={`${id}-anrede`}
          defaultValue={state.anrede}
          onValueChange={value => dispatch({ anrede: value })}
          items={[
            { text: 'keine Angabe', value: '' },
            { text: 'Frau', value: 'Frau' },
            { text: 'Herr', value: 'Herr' },
          ]}
        />
        <div className='form-field'>
          <Input
            name='vorname'
            value={state.vorname}
            onChange={e => dispatch({ vorname: e.target.value })}
            id={`${id}-vorname`}
            label='Vorname'
            type='text'
          />
        </div>
        <div className='form-field'>
          <Input
            name='nachname'
            value={state.nachname}
            onChange={e => dispatch({ nachname: e.target.value })}
            label='Nachname'
            type='text'
            id={`${id}-nachname`}
          />
        </div>
        {is_business ? (
          <div className='form-field'>
            <Input
              label='Unternehmen'
              name='unternehmen'
              value={state.unternehmen}
              onChange={e => dispatch({ unternehmen: e.target.value })}
              id={`${id}-unternehmen`}
              type='text'
            />
          </div>
        ) : null}
        <div className='form-field'>
          <Input
            label={
              <>
                E-Mailadresse <sup>*</sup>
              </>
            }
            value={state.email}
            onChange={e => dispatch({ email: e.target.value })}
            type='email'
            id={`${id}-email`}
            name='email'
            required
          />
        </div>
        <div className='form-field'>
          <span className='text-small'>
            <sup>*</sup>
            erforderliche Felder
          </span>
        </div>
        <div className='form-field data-protection'>
          <Checkbox
            id={`${id}-datenschutz`}
            name='datenschutz'
            value='checked'
            checked={state.datenschutz}
            onCheckedChange={value => dispatch({ datenschutz: value })}
            required
            label={
              <p>
                Ja, ich abonniere den kostenlosen Business Newsletter der Stadtwerke Witten GmbH.
                <span className='text-normal'>
                  Ich bin damit einverstanden, dass meine Nutzung des Newsletters personenbezogen
                  ausgewertet wird, um ihn zukünftig verbessern zu können. Nähere Informationen
                  ergeben sich aus den <Link href='/datenschutz'> Datenschutzbestimmungen</Link>.
                </span>
              </p>
            }
          />
        </div>
        <Button type='submit' primary>
          Jetzt anmelden
        </Button>
      </form>
      {status === 'success' ? (
        <div className='newsletterstate message message--success'>
          <p>
            Herzlichen Dank für Ihre Anmeldung zu unserem Business Newsletter. Sie erhalten von uns
            in Kürze eine Email mit einem Link. Mit diesem Bestätigungslink stellen wir sicher, dass
            Sie die Anmeldung persönlich initiiert haben.
          </p>
        </div>
      ) : null}
      {status === 'error' ? (
        <div className='newsletterstate message message--error'>
          <p>Leider ist etwas schief gegangen... :( </p>
        </div>
      ) : null}
    </div>
  );
}

export default NewsletterRegister;
