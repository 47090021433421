import SliderComponent from '@components/Slider';

import { WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@utils/url';
import Image from 'next/image';
import Picture from '@components/Picture/Picture';

type Props = WagtailStreamfieldImage[];

function Slider(props: Props) {
  // return <Debug data={props} />;

  if (!props) {
    return null;
  }

  return (
    <div className='streamfield slider'>
      <SliderComponent>
        {Object.values(props).map(image =>
          image.mobile ? (
            <Picture
              key={image.id}
              image={addBaseUrl(image.file.src)}
              mobileImage={addBaseUrl(image.mobile.src)}
              className='slider__image'
              common={{
                alt: image.file.alt,
                width: image.mobile.width,
                height: image.mobile.height,
              }}
            />
          ) : (
            <Image
              key={image.id}
              src={addBaseUrl(image.file.src)}
              alt={image.file.alt}
              fill
              className='slider__image'
            />
          ),
        )}
      </SliderComponent>
    </div>
  );
}
export default Slider;
