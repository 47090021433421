import RawHtml from '@components/RawHtml';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import React, { PropsWithChildren } from 'react';

type Props = {
  title: string;
  text: string | React.ReactNode;
};

function Accordion(props: PropsWithChildren<Props>) {
  const { title, text } = props;

  return (
    <AccordionPrimitive.Item className='accordion' value={title}>
      <AccordionPrimitive.Header className='accordion__header'>
        <AccordionPrimitive.Trigger className='accordion__trigger'>
          {title}
          <ChevronDownIcon className='accordion__icon' />
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className='accordion__content'>
        {typeof text === 'string' ? <RawHtml html={text} /> : text}
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
}

export default Accordion;
