import useSWR from 'swr';
import { useToken } from '../Provider/TokenProvider';

const url = `${process.env.ENERGYSHOP_DOMAIN ?? process.env.NEXT_PUBLIC_ENERGYSHOP_DOMAIN}/api/`;

const endpoints = {
  offers: 'calc/offers',
  features: 'calc/features',
  rules: 'calc/rules',

  checkoutToken: 'checkouts',
  details: 'checkouts/',
  submit: 'checkouts//submit',
  sideselling: 'checkouts//sideselling',
  checkoutDelete: 'checkouts//delete',

  products: 'products',

  validate: 'validate',
};

const headers = {
  'Authorization-Token': `Token ${
    process.env.ENERGYSHOP_TOKEN ?? process.env.NEXT_PUBLIC_ENERGYSHOP_TOKEN
  }`,
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const getUrl = (token: string | undefined, endpoint: string) => {
  let request_path = endpoint;
  if (token) {
    request_path = request_path.replace('checkouts/', `checkouts/${token}`);
  }
  return url + request_path;
};

const client = async (path: string, options: Partial<RequestInit> | undefined = undefined) => {
  const response = await fetch(url + path, {
    method: 'GET',
    ...options,
    headers: {
      ...options?.headers,
      ...headers,
    },
  });

  if (!response.ok) {
    throw Error('Fehler');
  }
  return response.json();
};

function useApi<T>(
  endpoint: string,
  params?: Partial<RequestInit>,
  config?: Record<string, any>,
  enable: boolean = true,
) {
  const { token } = useToken();

  let request_path = endpoint;
  if (token) {
    request_path = request_path.replace('checkouts/', `checkouts/${token}`);
  }

  let cacheKey: null | string = null;
  if (enable) {
    if (params?.method) {
      cacheKey = `${endpoint}_${token}`;
    } else {
      cacheKey = `${endpoint}_${token}_${JSON.stringify(params)}`;
    }
  }

  const query = useSWR<T>(cacheKey, async () => client(request_path, params), {
    loadingTimeout: 3000,
    ...config,
  });
  return query;
}

export { endpoints, getUrl, headers, useApi };
