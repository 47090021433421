import RawHtml from '@components/RawHtml';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  id: string;
  label: string | ReactNode;
  value: string;
  name?: string;
  help_text?: string | JSX.Element;
  disabled?: boolean;
  required?: boolean;
  baseline?: boolean;
  smallLabel?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
};

function Checkbox(props: Props) {
  const {
    id,
    label,
    name,
    value,
    help_text,
    disabled,
    required,
    baseline,
    smallLabel,
    defaultChecked,
    checked,
    onCheckedChange,
  } = props;

  return (
    <div className={clsx('checkbox', { 'checkbox--baseline': baseline })}>
      <CheckboxPrimitive.Root
        className='checkbox__root'
        id={id}
        name={name ?? id}
        value={value}
        disabled={disabled}
        required={required}
        defaultChecked={defaultChecked}
        checked={checked}
        onCheckedChange={onCheckedChange}
      >
        <CheckboxPrimitive.Indicator className='checkbox__indicator'>
          <CheckIcon width={24} height={24} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <label
        className={clsx('checkbox__label', {
          'checkobx__label--small': smallLabel,
        })}
        htmlFor={id}
      >
        <span className='label__headline'>{label}</span>
        <div className='label__help_text'>
          {typeof help_text === 'string' ? <RawHtml html={help_text} /> : help_text}
        </div>
      </label>
    </div>
  );
}

export default Checkbox;
