import Icon from '@images/danke-bestellbestaetigung.svg';
import React from 'react';

type Props = {};

const ThankYou = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  // setTimeout(() => {
  //   window.localStorage.removeItem('token');
  // }, 2000);
  return (
    <div className='ThankYou' ref={ref}>
      <div className='icon'>
        <Icon />
      </div>
      <h2>Vielen Dank für Ihre Bestellung!</h2>
      <div className='thank-you__mail'>Sie erhalten in Kürze eine E-Mail von uns.</div>
      <br />
      <div>Öffnen Sie die E-Mail und klicken Sie auf den darin enthaltenen Bestätigungslink.</div>
      <div>
        Nachdem Sie diese Schritte abgeschlossen haben, erhalten Sie von uns die Auflistung der
        Vertragsdetails.
      </div>
      <br />
      <div>
        Sollten Sie innerhalb der nächsten fünf Minuten keine E-Mail von uns erhalten haben, melden
        Sie sich bitte telefonisch bei uns: Stadtwerke Witten Kundenbetreuung:{' '}
        <b>02302 - 91 73 600</b>
      </div>
      {/* <br />
      <div>Bitte überprüfe auch Ihren Spam-Ordner, falls Sie die E-Mail nicht sofort sehen.</div> */}
      <br />
    </div>
  );
});

ThankYou.displayName = 'ThankYou';

export default ThankYou;
