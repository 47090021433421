import clsx from 'clsx';
import React from 'react';

type Props = {
  checked: boolean;
};

const RadioButton = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div className='radiobutton' ref={ref}>
      <div className={clsx(props.checked ? 'circle checked' : 'circle')}>
        <span />
      </div>
    </div>
  );
});

RadioButton.displayName = 'RadioButton';

export default RadioButton;
