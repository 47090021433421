import React from 'react';
import RawHtml from '@components/RawHtml';
import { WagtailImage, WagtailSvg } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';

type Props = {
  title: string;
  subtitle?: string | null;
  text?: string | null;
  svg?: WagtailSvg | null;
  banner_icon_svg?: string | null;
  banner_icon_png?: WagtailImage | null;
};

function Hero(props: Props) {
  const { title, subtitle, text, svg, banner_icon_svg, banner_icon_png } = props;

  return (
    <div role='banner' className='hero flow'>
      <div className='wrapper'>
        <div className='hero__title'>
          {banner_icon_png ? (
            <div className='hero__icon'>
              <Image
                src={addBaseUrl(banner_icon_png.meta.download_url)}
                alt={banner_icon_png.title}
                className='icon'
                width={78}
                height={78}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            </div>
          ) : null}
          {banner_icon_svg ? <RawHtml className='hero__icon' html={banner_icon_svg} /> : null}
          {svg ? (
            <div className='hero__svg'>
              <Image src={svg.file} alt={svg.title} width='120' height='120' />
            </div>
          ) : null}
          <div className='helper'>
            <h1 className='hero__title--text'>{title}</h1>
            {subtitle ? <h2 className='hero__subtitle'>{subtitle}</h2> : null}
          </div>
        </div>

        {text ? <RawHtml className='hero__text' html={text} /> : null}
      </div>
    </div>
  );
}

export default Hero;
