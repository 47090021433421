import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const header = {
  'Authorization-Token': `Token ${
    process.env.ENERGYSHOP_TOKEN ?? process.env.NEXT_PUBLIC_ENERGYSHOP_TOKEN
  }`,
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

interface Auth {
  token: string | null;
  deleteToken: () => void;
}

const TokenContext = createContext<Auth>({
  token: null,
  deleteToken: () => {},
});

TokenContext.displayName = 'TokenContext';

interface Props {
  children: React.ReactElement;
}

function TokenProvider(props: Props) {
  const TOKEN_STORAGE_KEY = 'token';
  const [token, setToken] = useState<string | null>(null);

  const deleteToken = useCallback(() => {
    // const storedToken = window.localStorage.getItem(TOKEN_STORAGE_KEY);
    // fetch(
    //   `${
    //     process.env.ENERGYSHOP_DOMAIN ?? process.env.NEXT_PUBLIC_ENERGYSHOP_DOMAIN
    //   }/api/checkouts/${storedToken}/delete`,
    //   {
    //     method: 'POST',
    //     headers: header,
    //   },
    // ).then(data => {

    // });
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    setToken(null);

    window.location.reload();
  }, []);

  const obj = useMemo(
    () => ({
      token,
      deleteToken,
    }),
    [token, deleteToken],
  );

  React.useEffect(() => {
    const storedToken = window.localStorage.getItem(TOKEN_STORAGE_KEY);
    if (!storedToken) {
      // If no Token exist. Return a new one.
      window.localStorage.setItem(TOKEN_STORAGE_KEY, '-');
      fetch(
        `${
          process.env.ENERGYSHOP_DOMAIN ?? process.env.NEXT_PUBLIC_ENERGYSHOP_DOMAIN
        }/api/checkouts/`,
        {
          method: 'POST',
          headers: header,
        },
      )
        .then(data =>
          data
            .clone()
            .json()
            .then(tokenData => {
              window.localStorage.setItem(TOKEN_STORAGE_KEY, tokenData.checkout.token);
              setToken(tokenData.checkout.token);
            }),
        )
        .catch(() => {
          window.localStorage.setItem(TOKEN_STORAGE_KEY, '');
        });
    } else {
      // use the existing Token
      setToken(storedToken);
    }
  }, []);

  return <TokenContext.Provider value={obj}>{props.children}</TokenContext.Provider>;
}

function useToken() {
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error('useToken must be used within a TokenProvider');
  }

  return context;
}

export { TokenProvider, useToken };
