import React from 'react';
import Order from './Order';
import OrderProvider from './Provider';
import { ProductSetting } from './types';

type Props = {
  product_setting: ProductSetting;
  show_svg: boolean;
  product_id: number;
};

function OrderContext(props: Props) {
  return (
    <OrderProvider>
      <Order
        show_svg={props.show_svg}
        product_setting={props.product_setting}
        product_id={props.product_id}
      />
    </OrderProvider>
  );
}

export default OrderContext;
