import RawHtml from '@components/RawHtml';
import Image from 'next/image';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Order from '../Order';
import { useStorage } from '../Provider/StorageProvider';
import { endpoints, useApi } from '../hooks/api';
import { ProductSetting, SidesellingCategory, Sideselling as TSideselling } from '../types';

type Props = {
  pev_product_name: string;
  sideselling_option: ProductSetting | undefined;
  sideselling: boolean;
  setSideselling: Dispatch<SetStateAction<boolean>>;
  startStep: number | undefined;
  reopenSideselling: boolean;
};

type ItemProps = {
  // category: SidesellingCategory;
  sideselling_option: ProductSetting | undefined;
  sideselling: boolean;
  setSideselling: Dispatch<SetStateAction<boolean>>;
  isSideselling: boolean;
  startStep: number | undefined;
  reopenSideselling: boolean;
};

const SidesellingItem = React.forwardRef<HTMLDivElement, ItemProps>((props, ref) => {
  const {
    sideselling_option,
    sideselling,
    setSideselling,
    isSideselling,
    startStep,
    reopenSideselling,
  } = props;
  const { storage } = useStorage();
  // using editedSidesell to determine whether there has been a kwh edit in sidesell to reopen sideselling right away.
  const [editedSidesell, setEditedSidesell] = useState(reopenSideselling ?? false);

  useEffect(() => {
    // reworked to use editedSidesell aswell as isSideselling. Needs to be tested further
    setSideselling((isSideselling || editedSidesell) ?? false);
  }, [setSideselling, isSideselling, editedSidesell]);

  const currentProduct = storage?.items?.filter(
    item => item.product_category === sideselling_option?.product_name,
  )?.[0];

  return (
    <div className='sideselling-item' ref={ref}>
      {!sideselling && !editedSidesell ? (
        <>
          <h2>{sideselling_option?.sideselling_headline}</h2>
          <div className='item-container'>
            <div className='tariff-info'>
              <div className='tariff-info__logo'>
                {sideselling_option?.icon ? (
                  <Image
                    src={sideselling_option.icon.file}
                    alt={sideselling_option.icon.title}
                    width='120'
                    height='120'
                  />
                ) : null}
              </div>
              <div className='tariff-info__text'>
                <RawHtml html={sideselling_option?.sideselling_text} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='sideselling-container'>
          {sideselling_option ? (
            <Order
              show_svg={false}
              isSideselling
              startStep={startStep}
              product_setting={sideselling_option}
              setEditedSidesell={setEditedSidesell}
            />
          ) : null}
        </div>
      )}
    </div>
  );
});

SidesellingItem.displayName = 'SidesellingItem';
// export default SidesellingItem;

const Sideselling = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    pev_product_name,
    sideselling_option,
    sideselling,
    setSideselling,
    startStep,
    reopenSideselling,
  } = props;
  const { storage } = useStorage();

  const { data: sidesellingData } = useApi<TSideselling>(endpoints.sideselling);

  const SidesellingByCategories = useMemo(() => {
    const data: SidesellingCategory[] = [];
    sidesellingData?.categories.forEach(item => {
      storage?.items?.filter(
        storedItem => storedItem.consumption && storedItem.product_category === item.category,
      );
      if (pev_product_name !== item.category) {
        data.push(item);
      }
    });
    return data;
  }, [pev_product_name, sidesellingData, storage?.items]);

  return (
    <div className='Sideselling' ref={ref}>
      {SidesellingByCategories.map(category =>
        sideselling_option?.product_name === category.category ? (
          <div key={category.category}>
            <SidesellingItem
              sideselling_option={sideselling_option}
              startStep={startStep}
              reopenSideselling={reopenSideselling}
              isSideselling={
                storage
                  ? storage.items.filter(a => a.product_category === category.category).length > 0
                  : false
              }
              sideselling={sideselling}
              setSideselling={setSideselling}
            />
          </div>
        ) : null,
      )}
    </div>
  );
});

Sideselling.displayName = 'Sideselling';

export default Sideselling;
