import { Streamfield } from '@/types';
import React from 'react';
import AccordionComponent from '@components/AccordionsTable';
import Streamfields from '@streamfields/Streamfields';

type Props = {
  accordions: {
    title: string;
    content: Streamfield[];
  }[];
  blocktitle?: string;
};

function AccordionsTable(props: Props) {
  const { accordions: accordionElements, blocktitle } = props;
  const newAccordionElements = accordionElements.map(item => {
    return {
      title: item.title,
      text: <Streamfields fields={item.content} />,
    };
  });

  return (
    <div className='accordions accordions-table streamfield'>
      {blocktitle ? (
        <div className='accordion__header'>
          {blocktitle ? <h2 className='accordions__header'>{blocktitle}</h2> : null}
        </div>
      ) : null}
      <AccordionComponent accordions={newAccordionElements} />
    </div>
  );
}

export default AccordionsTable;
