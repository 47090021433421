import React, { Dispatch, SetStateAction, useEffect } from 'react';

// import OrderForm from '@components/OrderForm';
import ProcessBar from '@components/ProcessBar';
import { useRouter } from 'next/router';
import ContractPeriod from './Steps/ContractPeriod';
import DataForm from './Steps/DataForm';
import KwhCalculator from './Steps/KwhCalculator';
import TarifDetail from './Steps/TarifDetail';
import ThankYou from './Steps/ThankYou';
// import Oekostrom from './steps/Oekostrom';
// import Offer from './steps/Offer';

import { useStorage } from './Provider/StorageProvider';
import { useToken } from './Provider/TokenProvider';
import GreenEnergy from './Steps/GreenEnergy';
import OrderOverview from './Steps/OrderOverview';
import PaymentForm from './Steps/PaymentForm';
import { endpoints, useApi } from './hooks/api';
import { ItemTariff, ProductSetting, Order as TOrder } from './types';

type Props = {
  product_setting: ProductSetting;
  isSideselling?: boolean;
  show_svg: boolean;
  product_id?: number;
  setEditedSidesell?: Dispatch<SetStateAction<boolean>>;
  startStep?: number | undefined;
};

const stepsMatch: Record<string, number[]> = {
  _: [0, 1, 4, 5, 6, 7],
  electric: [0, 1, 2, 4, 5, 6, 7],
  sidesellingGas: [0, 1, 4, 6],
  sidesellingElectric: [0, 1, 2, 4, 6],
};

export function numberWithCommas(x: number, fixed: number | undefined = undefined) {
  const x_string = x.toFixed(fixed !== undefined ? fixed : 2);
  const parts = x_string.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}

const Order = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const router = useRouter();
  const { kwhConsumption } = router.query;
  const [step, setStep] = React.useState(-1);
  const [kwh, setKwh] = React.useState<number | undefined>();
  const [activeTariff, setActiveTariff] = React.useState<number[]>([]);
  const [selectedFeatures, setSelectedFeatures] = React.useState<Record<string, any>>([]);
  const { storage: storedData, setStorage: setstoredData } = useStorage();
  const { deleteToken, token } = useToken();

  const tariff_type = props.product_setting.product_name;

  let steps: number[] = stepsMatch._;
  if (props.isSideselling && tariff_type === 'electric') {
    steps = stepsMatch.sidesellingElectric;
  } else if (props.isSideselling && tariff_type === 'gas') {
    steps = stepsMatch.sidesellingGas;
  } else if (!props.isSideselling && stepsMatch[tariff_type]?.length) {
    steps = stepsMatch[tariff_type];
  }

  let hideSteps = [0, 7];
  if (!props.isSideselling && tariff_type === 'electric') {
    hideSteps = [0, 7];
  } else if (props.isSideselling && tariff_type === 'electric') {
    hideSteps = [0, 7];
  }

  const isSideselling = props.isSideselling === true;

  const { data, isLoading, error } = useApi<TOrder>(
    endpoints.details,
    undefined,
    undefined,
    !storedData && !!token,
  );
  if (error && token && !storedData) {
    deleteToken();
  }

  const getCurrentProduct = React.useCallback(() => {
    if (storedData?.items) {
      for (let i = 0; i < storedData?.items?.length; i += 1) {
        const element = storedData?.items[i];
        if (props.product_id) return;
        if (element.product_category === tariff_type) {
          return element;
        }
      }
    }
  }, [storedData, tariff_type, props.product_id]);

  // If querparameter exists skip first step (used to make the streamfield for kwhconsumption work)
  useEffect(() => {
    if (
      kwhConsumption &&
      !Number.isNaN(+kwhConsumption) &&
      +kwhConsumption > 0 &&
      +kwhConsumption < props.product_setting.max_kwh
    ) {
      setKwh(+kwhConsumption);
      setStep(1);
      router.replace({ pathname: window.location.pathname, query: null }, undefined, {
        shallow: true,
      });
    } else if (
      kwhConsumption &&
      !Number.isNaN(+kwhConsumption) &&
      +kwhConsumption > 0 &&
      +kwhConsumption >= props.product_setting.max_kwh
    ) {
      setKwh(+kwhConsumption);
      setStep(0);
      router.replace({ pathname: window.location.pathname, query: null }, undefined, {
        shallow: true,
      });
    }
  }, [kwhConsumption, props.product_setting.max_kwh, router]);

  useEffect(() => {
    if (data) {
      setstoredData(data);
    }
  }, [data, setstoredData]);

  const currentProcuct = getCurrentProduct();

  const getCurrentProductFeatureProducts = React.useCallback(() => {
    const products: ItemTariff[] = [];
    if (storedData?.items?.length) {
      for (let i = 0; i < storedData?.items?.length; i += 1) {
        const item = storedData?.items[i];
        if (currentProcuct?.product_id === item.properties?.belongs_to) {
          products.push(item);
        }
      }
    }
    return products;
  }, [currentProcuct, storedData]);

  const currentProductFeatureProducts = getCurrentProductFeatureProducts();

  const scrollToTop = () => {
    const elem: HTMLElement | null = document.getElementById(`order-${tariff_type}`);
    if (elem && elem.firstChild) {
      // document.documentElement.scrollTop = elem.offsetTop;
      // @ts-expect-error
      elem.firstChild.scrollIntoView();
    }
  };

  const goBack = () => {
    scrollToTop();
    setStep(steps[steps.indexOf(step) - 1]);
  };

  const goNext = () => {
    scrollToTop();
    if (!(steps[steps.length - 1] === step)) {
      setStep(steps[steps.indexOf(step) + 1]);
    }
  };

  const selectCheckpoint = React.useCallback(
    (tariff?: ItemTariff) => {
      if (step !== -1 || props.product_id || isLoading) return;

      if (props.startStep !== null && props.startStep !== undefined && props.startStep > -1) {
        setStep(props.startStep);
        return;
      }

      if (tariff?.consumption_point?.meter_number && storedData?.payment?.debit_mandate?.iban) {
        setStep(5);
      } else if (tariff?.consumption_point?.meter_number) {
        setStep(4);
      } else if (tariff?.consumption) {
        setKwh(tariff.consumption);
        setStep(1);
      } else if (step === -1 && storedData) {
        setStep(0);
      }
    },
    [step, props.product_id, props.startStep, isLoading, storedData],
  );

  useEffect(() => {
    selectCheckpoint(currentProcuct);
  }, [currentProcuct, selectCheckpoint]);

  const getComponentForStep = () => {
    const defaultProps = {
      nextStep: goNext,
      previousStep: goBack,
      currentProduct: currentProcuct,
      currentProductFeatureProducts,
      isSideselling,
      product_id: props.product_id,
      tariffCategory: tariff_type,
    };

    switch (step) {
      case 0:
        return (
          <KwhCalculator
            {...defaultProps}
            setKwh={setKwh}
            kwh={kwh}
            product_setting={props.product_setting}
          />
        );
      case 1:
        return (
          <ContractPeriod
            {...defaultProps}
            product_setting={props.product_setting}
            kwh={kwh}
            setStep={setStep}
            setActiveTariff={setActiveTariff}
            setSelectedFeatures={setSelectedFeatures}
            setEditedSidesell={props.setEditedSidesell}
          />
        );
      case 2:
        return (
          <GreenEnergy
            {...defaultProps}
            kwh={kwh}
            activeTariff={activeTariff}
            product_setting={props.product_setting}
            selectedFeatures={selectedFeatures}
            setStep={setStep}
            setSelectedFeatures={setSelectedFeatures}
            setEditedSidesell={props.setEditedSidesell}
          />
        );
      /* case 3:
        return <TarifDetail {...defaultProps} />; */
      case 4:
        return <DataForm {...defaultProps} product_setting={props.product_setting} />;
      case 5:
        return <PaymentForm {...defaultProps} product_setting={props.product_setting} />;
      case 6:
        return (
          <OrderOverview
            {...defaultProps}
            product_setting={props.product_setting}
            setStep={setStep}
          />
        );
      case 7:
        return <ThankYou />;

      default:
        return null;
    }
  };

  return (
    <div className='Order' id={`order-${tariff_type}`} ref={ref}>
      {/* Condition before fully removing sideselling progressbar
       {props.isSideselling && step === steps[steps.length - 1] ? null : ( 
      */}
      {props.isSideselling ? null : (
        <ProcessBar
          // firstIcon={Icon}
          steps={steps}
          currentStep={steps.indexOf(step)}
          hideSteps={hideSteps}
          stepNames={steps.map(
            id =>
              ['', 'Laufzeit', 'Ökostrom', 'Angebot', 'Adresse', 'Zahlungsart', 'Bestellen'][id],
          )}
        />
      )}
      {storedData?.completed_at ? (
        <div>
          Die Daten wurden bereits eingereicht. Klicken Sie auf den Link in der E-Mail, um Ihre
          E-Mail-Adresse zu bestätigen.
        </div>
      ) : (
        getComponentForStep()
      )}
      {/* !props.isSideselling && currentProcuct?.consumption ? (
        <div
          className='zuruecksetzen'
          onClick={() => {
            deleteToken();
          }}
          onKeyUp={() => {
            deleteToken();
          }}
          role='presentation'
        >
          Eingabe zurücksetzen
        </div>
      ) : null */}
    </div>
  );
});

Order.displayName = 'Order';

export default Order;
