import React, { ReactNode } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';

type Props = { text: ReactNode };

function Label(props: Props & LabelPrimitive.LabelProps) {
  const { text, ...rest } = props;

  return (
    <LabelPrimitive.Root className='label' {...rest}>
      {text}
    </LabelPrimitive.Root>
  );
}

export default Label;
