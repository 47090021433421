import Link from 'next/link';
import React from 'react';
import { removeBaseUrl, addBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailStreamfieldImage } from '@/types';
import Image from 'next/image';
import RawHtml from '@components/RawHtml/RawHtml';
import Picture from '@components/Picture/Picture';

type Props = {
  title: string;
  text: string;
  image: WagtailStreamfieldImage;
  button_page: WagtailPage | null;
  button_url: string | null;
  button_text: string;
};

function CTA(props: Props) {
  const { button_page, button_url, button_text, title, image, text } = props;

  return (
    <div className='streamfield cta'>
      <div className='container'>
        <div className='box'>
          {title ? <div className='box__header'>{title}</div> : ''}
          <div className='box__text'>
            <RawHtml html={text} />
          </div>
          <Link
            href={button_page ? removeBaseUrl(button_page.full_url) : button_url ?? ''}
            passHref
            className='button button--primary'
          >
            {button_text}
          </Link>
        </div>
      </div>
      {image.mobile ? (
        <Picture
          image={addBaseUrl(image.file.src)}
          mobileImage={addBaseUrl(image.mobile.src)}
          fill
          sizes='100vw'
          className='cta__image'
          common={{ alt: image.file.alt, width: image.mobile.width, height: image.mobile.height }}
        />
      ) : (
        <Image src={addBaseUrl(image.file.src)} alt={image.file.alt} className='cta__image' />
      )}
    </div>
  );
}

export default CTA;
