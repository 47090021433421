import React from 'react';
import { StorageProvider } from './StorageProvider';
import { TokenProvider } from './TokenProvider';

interface Props {
  children: React.ReactElement;
}

function AppProvider(props: Props) {
  return (
    <TokenProvider>
      <StorageProvider>{props.children}</StorageProvider>
    </TokenProvider>
  );
}

export default AppProvider;
