import { Accordion as AccordionType } from '@/types';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import clsx from 'clsx';
import RawHtml from '@components/RawHtml';
import AccordionTableElement from './AccordionTableElement';

type Props = {
  className?: string;
  accordions: AccordionType[];
  defaultOpen?: boolean;
};

function AccordionsTable(props: Props) {
  const { accordions, className, defaultOpen } = props;

  return (
    <AccordionPrimitive.Root
      type='single'
      collapsible
      className={clsx('flow', className)}
      defaultValue={defaultOpen ? accordions[0].title : undefined}
    >
      {accordions.map(accordionElement => (
        <AccordionTableElement key={accordionElement.title} title={accordionElement.title}>
          {typeof accordionElement.text === 'string' ? (
            <RawHtml className='richtext-wrapper' html={accordionElement.text} />
          ) : (
            accordionElement.text
          )}
        </AccordionTableElement>
      ))}
    </AccordionPrimitive.Root>
  );
}

export default AccordionsTable;
