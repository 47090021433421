import { WagtailPage, WagtailStreamfieldImage } from '@/types';
import clsx from 'clsx';
import React from 'react';
import NextImage from 'next/image';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import Link from 'next/link';

type Props = {
  image: WagtailStreamfieldImage;
  full_width: boolean;
  maxwidth?: number | null;
  imagelink_page: WagtailPage | null;
  imagelink_url: string | null;
  imagecaption?: string | null;
};

function Image(props: Props) {
  const { full_width, image, maxwidth, imagelink_page, imagelink_url, imagecaption } = props;
  const getNextImage = () => (
    <NextImage
      className='image__element'
      src={addBaseUrl(image.file.src)}
      alt={image.file.alt}
      width={maxwidth || image.file.width}
      height={image.file.height}
      quality={75}
      sizes='(max-width: 768px) 100vw,
      (max-width: 1200px) 50vw,
      50vw'
    />
  );

  return (
    <div
      className={clsx('streamfield', 'image', {
        'image--full-width': full_width,
      })}
    >
      {imagelink_page || imagelink_url ? (
        <div className='imagecontainer'>
          <Link
            href={imagelink_page ? removeBaseUrl(imagelink_page.full_url) : imagelink_url ?? ''}
            passHref
            target={imagelink_url ? '_blank' : undefined}
          >
            {getNextImage()}
          </Link>
          <span className='imagecaption'>{imagecaption}</span>
        </div>
      ) : (
        <div className='imagecontainer'>
          {getNextImage()}
          <span className='imagecaption'>{imagecaption}</span>
        </div>
      )}
    </div>
  );
}

export default Image;
