/* eslint-disable react/no-array-index-key */
import Fieldset from '@components/Form/Fieldset';
import * as LabelPrimitive from '@radix-ui/react-label';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import clsx from 'clsx';

type RadioButtonItemData = {
  label: string;
  value?: string;
};

type Props = {
  id: string;
  items: RadioButtonItemData[];
  label: string;
  row?: boolean;
  required?: boolean;
};

function RadioGroup(props: Props) {
  const { id, items, label, row, required } = props;

  return (
    <Fieldset className={clsx('radio-group', { 'radio-group--row': row })} legend={label} asLabel>
      <RadioGroupPrimitive.Root className='radio-group__root' name={id} required={required}>
        {items.map((item, index) => (
          <div className='radio-group__item-container' key={`${id}-${index}`}>
            <RadioGroupPrimitive.Item
              className='radio-group__item'
              value={item.value ?? item.label}
              id={`${id}-${index}`}
              required={required}
            >
              <RadioGroupPrimitive.Indicator className='radio-group__indicator' />
            </RadioGroupPrimitive.Item>
            <LabelPrimitive.Root className='radio-group__label' htmlFor={`${id}-${index}`}>
              {item.label}
            </LabelPrimitive.Root>
          </div>
        ))}
      </RadioGroupPrimitive.Root>
    </Fieldset>
  );
}

export default RadioGroup;
