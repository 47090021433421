import React from 'react';
import streamfields from '@/streamfields';
import type { Streamfields as TStreamfields } from '@/streamfields';
import { Streamfield } from '../types';

type Props = {
  fields: Streamfield[];
  blocksLoaderData?: Partial<Record<TStreamfields, unknown>>;
};

function Streamfields(props: Props) {
  const { fields, blocksLoaderData } = props;

  return (
    <>
      {fields.map(field => {
        const Component = streamfields[field.type];
        if (!Component) {
          return (
            <h2 key={field.id}>
              Component <strong>{field.type}</strong> not found
            </h2>
          );
        }

        const loaderData = blocksLoaderData?.[field.id as TStreamfields];

        if (loaderData || field.type === 'container') {
          return (
            <Component
              key={field.id}
              loaderData={loaderData}
              {...(typeof field.value === 'string' ? { value: field.value } : field.value)}
            />
          );
        }

        return (
          <Component
            key={field.id}
            {...(typeof field.value === 'string' ? { value: field.value } : field.value)}
          />
        );
      })}
    </>
  );
}

export default Streamfields;
