import React, { createContext, useContext, useMemo, useState } from 'react';
import { Order } from '../types';

interface Storage {
  storage: Order | undefined;
  setStorage: React.Dispatch<React.SetStateAction<Order | undefined>>;
}

const StorageContext = createContext<Storage>({
  storage: undefined,
  setStorage: () => {},
});

StorageContext.displayName = 'StorageContext';

interface Props {
  children: React.ReactElement;
}

function StorageProvider(props: Props) {
  const [storage, setStorage] = useState<Order | undefined>(undefined);
  const obj = useMemo(
    () => ({
      storage,
      setStorage,
    }),
    [storage, setStorage],
  );

  return <StorageContext.Provider value={obj}>{props.children}</StorageContext.Provider>;
}

function useStorage() {
  const context = useContext(StorageContext);
  if (context === undefined) {
    throw new Error('useToken must be used within a TokenProvider');
  }

  return context;
}

export { StorageProvider, useStorage };
