import { WagtailSvg } from '@/types';
import { addBaseUrl } from '@utils/url';
import Link from 'next/link';
import React from 'react';
import Image from 'next/image';
import RawHtml from '@components/RawHtml';

type ServiceType = {
  title: string;
  svg?: WagtailSvg | null;
  text: string;
  button_text: string;
  button_link: string;
};

type Props = {
  headline?: string;
  services: ServiceType[];
};

function Services(props: Props) {
  const { headline, services } = props;
  return (
    <div className='streamfield services-block'>
      {headline ? <h2 className='services-block__title'>{headline}</h2> : null}
      <div className='services__service-container'>
        {services.map(item => (
          <div className='service__container' key={item.title}>
            <div className='service__icon-container'>
              {item.svg ? (
                <Image
                  src={addBaseUrl(item.svg?.file)}
                  width={80}
                  height={68}
                  alt={item.svg?.title}
                  className='service__icon'
                />
              ) : null}
            </div>
            <div className='service__content-container'>
              <h3>{item.title}</h3>
              <div className='service__description'>
                <RawHtml html={item.text} />
              </div>
            </div>
            <div className='service__button-container'>
              <Link href={item.button_link} target='_blank' className='button services__button'>
                {item.button_text}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
