import React from 'react';

type Props = {
  height: number;
  width?: number;
};

function SolarRechner(props: Props) {
  const { height, width } = props;
  return (
    <div className='streamfield solar_rechner'>
      <iframe
        height={`${height}px`}
        width={width ? `${width}px` : '100%'}
        // frameborder="0"
        name='Photovoltaik Rechner'
        scrolling='yes'
        src='https://frontend.vlink.com/?key=wtpvst#/solar/postalcode'
        title='Photovoltaik Rechner'
      />
    </div>
  );
}

export default SolarRechner;
