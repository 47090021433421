import React from 'react';
import Parser from 'rss-parser';
import Link from 'next/link';

type RSSFeedItem = {
  title: string;
  link: string;
  pubDate: string;
  content: string;
  contentSnippet: string;
  guid: string;
  isoDate: string;
};

type RSSFeedData = {
  title: string;
  description: string;
  pubDate: string;
  link: string;
  language: string;
  feedUrl: string;
  paginationLinks: {
    self: string;
  };
  items: RSSFeedItem[];
};

// eslint-disable-next-line react/no-unused-prop-types
type Props = { feed_url: string; title: string };

type PropsWithLinks = Props & {
  loaderData: { title: string; link: string; pubDate: string }[];
};

function RSSFeed(props: PropsWithLinks) {
  const { title, loaderData } = props;

  if (!loaderData?.length) return null;

  return (
    <div className='streamfield rss-feed'>
      <h2 className='rss-feed__title'>{title}</h2>
      {loaderData
        ?.sort((a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime())
        .map(item => (
          <Link
            key={item.link}
            href={item.link}
            className='rss-feed__item'
            target='_blank'
            rel='noreferrer noopener'
          >
            {item.title}
          </Link>
        ))}
    </div>
  );
}

export default RSSFeed;

export async function loader(props: Props) {
  const { feed_url } = props;
  try {
    const parser = new Parser();
    const feed = (await parser.parseURL(feed_url)) as RSSFeedData;

    return (
      feed?.items?.map(item => ({
        title: item.title,
        link: item.link,
        pubDate: item.isoDate,
      })) ?? []
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    // return empty Array im request fails to prevent app from crashing
    return [];
  }
}
