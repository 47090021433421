type Props = {
  donation: string;
};

function DonationBooth(props: Props) {
  const { donation } = props;

  return (
    <div className='streamfield donation'>
      <div className='donation__header h3'>Aktueller Spendenbetrag:</div>
      <div className='donation__sum h2'>{donation}</div>
    </div>
  );
}

export default DonationBooth;
