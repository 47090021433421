import Button from '@components/Button';
import { Checkbox } from '@components/Form';
import Link from 'next/link';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Accordion from '@components/Accordions';
import EditIcon from '@images/pencil.svg';
import RawHtml from '@components/RawHtml';
import { useStorage } from '../Provider/StorageProvider';
import { useToken } from '../Provider/TokenProvider';
import { endpoints, getUrl, headers } from '../hooks/api';
import { ItemTariff, ProductSetting } from '../types';
import Sideselling from './Sideselling';
import TrashIcon from './TrashIcon.svg';

type Props = {
  // previousStep: () => void;
  nextStep: () => void;
  // tariffCategory: TariffCategory;
  setStep: Dispatch<SetStateAction<number>>;

  isSideselling: boolean;
  product_setting: ProductSetting;
};

const OrderOverview = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { storage, setStorage } = useStorage();
  const { token, deleteToken } = useToken();
  const [openOverview, setOpenOverview] = useState<boolean>(false);
  const [sideselling, setSideselling] = useState<boolean>(false);
  const [startStep, setStartStep] = useState<number | undefined>(undefined);
  const [reopenSideselling, setReopenSideselling] = useState<boolean>(false);

  const [agb, setAgb] = useState(false);
  const [dataSecurity, setDataSecurity] = useState(false);

  const isOpenOverviewAvailable = useMemo(() => {
    return storage?.items.every(
      obj => !!obj?.consumption_point?.meter_number || obj.properties?.belongs_to,
    );
  }, [storage?.items]);

  const scrollToTop = (tariff_type: string) => {
    const elem: HTMLElement | null = document.getElementById(`order-${tariff_type}`);
    if (elem && elem.firstChild) {
      // document.documentElement.scrollTop = elem.offsetTop;
      // @ts-expect-error
      elem.firstChild.scrollIntoView();
    }
  };

  // check if currently in sideselling with finished products or not in sideselling
  const showSidesellingCancel = useMemo(() => {
    const isAtCompleted =
      (isOpenOverviewAvailable && !sideselling) ||
      (isOpenOverviewAvailable &&
        sideselling &&
        storage?.items?.filter(item => item.consumption_point?.meter_number) &&
        storage?.items?.filter(item => item.consumption_point?.meter_number).length >= 2);
    return !isAtCompleted;
  }, [isOpenOverviewAvailable, sideselling, storage?.items]);

  // automatically open overview if sideselling is done (skip last step)
  useEffect(() => {
    if (
      isOpenOverviewAvailable &&
      storage?.items?.filter(item => item.consumption_point?.meter_number) &&
      storage?.items?.filter(item => item.consumption_point?.meter_number).length >= 2
    ) {
      setOpenOverview(true);
    }
  }, [isOpenOverviewAvailable, storage?.items]);

  useEffect(() => {
    const elem = document.querySelector('#overviewheadline');
    if (openOverview) {
      elem?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [openOverview]);

  const submitUrl = token ? getUrl(token, endpoints.submit) : '';

  function removeProducts(currentItem: number) {
    const allIds: number[] = [currentItem];

    if (storage?.items) {
      const belongingProducts = storage.items.filter(item => {
        if (item.properties && item.properties.belongs_to === currentItem) return item;
        return null;
      });
      const belongingIds = belongingProducts.map(belItem => {
        return belItem.product_id;
      });
      allIds.push(...belongingIds);
    }

    if (token) {
      fetch(getUrl(token, endpoints.checkoutDelete), {
        method: 'POST',
        body: JSON.stringify({ products: [...allIds] }),
        headers,
      }).then(res => {
        res.json().then(newdata => {
          setStorage({ ...storage, ...newdata });
        });
      });
    }
  }

  // remove sideselling product then open overview with remaining products
  const removeSidesellingProdToOverview = React.useCallback(() => {
    const product_id = storage?.items?.filter(
      item => item.product_category === props.product_setting.sideselling_options?.[0].product_name,
    )?.[0]?.product_id;

    const allIds = [product_id];
    const belongingProducts = storage?.items.filter(item => {
      if (item.properties && item.properties.belongs_to === product_id) return item;
      return null;
    });
    const belongingIds = belongingProducts?.map(belItem => {
      return belItem.product_id;
    });
    if (belongingIds) {
      allIds.push(...belongingIds);
    }

    if (!product_id) {
      setSideselling(false);
      return;
    }

    if (token) {
      fetch(getUrl(token, endpoints.checkoutDelete), {
        method: 'POST',
        body: JSON.stringify({ products: [...allIds] }),
        headers,
      }).then(res => {
        res.json().then(newdata => {
          setStorage({ ...storage, ...newdata });
          setSideselling(false);
          if (
            newdata.items.every(
              (obj: ItemTariff) =>
                !!obj?.consumption_point?.meter_number || obj.properties?.belongs_to,
            )
          ) {
            setOpenOverview(true);
          }
        });
      });
    }
  }, [storage, token, props.product_setting.sideselling_options, setStorage]);

  if (props.isSideselling) return null;

  const submitOrder = () => {
    if (agb && dataSecurity) {
      fetch(submitUrl, {
        method: 'POST',
        headers,
      }).then(req => {
        if (req.status < 300) props.nextStep();
      });
    }
  };
  const birthdate = new Date(storage?.customer.birthdate || '');

  const get_product_name = (name: string, option: ProductSetting): string | undefined => {
    if (option.product_name === name) {
      return option.name;
    }
    if (!option?.sideselling_options?.length) {
      return undefined;
    }
    for (let i = 0; i < option.sideselling_options.length; i += 1) {
      const real_name = get_product_name(name, option?.sideselling_options?.[i]);
      if (real_name) {
        return real_name;
      }
    }
    return undefined;
  };

  return (
    <div className='OrderOverview' ref={ref}>
      {!openOverview ? (
        <>
          <Sideselling
            pev_product_name={props.product_setting.product_name}
            sideselling_option={props.product_setting.sideselling_options?.[0]}
            sideselling={sideselling}
            reopenSideselling={reopenSideselling}
            setSideselling={setSideselling}
            startStep={startStep}
          />
          <div className='OrderOverview__button-container'>
            {!sideselling ? (
              <Button primary onClick={() => setSideselling(true)}>
                {props.product_setting.sideselling_options?.[0].name &&
                props.product_setting.sideselling_options?.[0].name === 'Strom'
                  ? `${props.product_setting.sideselling_options?.[0].name} dazu buchen`
                  : `${props.product_setting.sideselling_options?.[0].name}tarif dazu buchen`}
              </Button>
            ) : null}
            {isOpenOverviewAvailable && startStep === undefined ? (
              <Button
                primary={!showSidesellingCancel}
                onClick={() => setOpenOverview(!openOverview)}
              >
                {showSidesellingCancel
                  ? `Weiter ohne ${props.product_setting.sideselling_options?.[0].name}`
                  : 'Zur Bestellübersicht'}
              </Button>
            ) : null}
            {!isOpenOverviewAvailable && sideselling ? (
              <Button
                onClick={() => {
                  removeSidesellingProdToOverview();
                }}
              >
                Weiter ohne {props.product_setting.sideselling_options?.[0].name}
              </Button>
            ) : null}
          </div>
        </>
      ) : null}

      {openOverview ? (
        <div>
          <h2 id='overviewheadline'>Übersicht</h2>
          <div className='productitem-wrapper'>
            {storage?.items
              .filter(obj => !!obj.product_category)
              .sort((a, b) => {
                const productB = b.product_category === props.product_setting.product_name ? 0 : 1;
                const productA = a.product_category === props.product_setting.product_name ? 0 : 1;
                return productA - productB;
              })
              .map(item => (
                <div className='item-container product-item' key={item.product_id}>
                  <div className='OrderOverview__contract-container'>
                    <h3>{get_product_name(item.product_category, props.product_setting)}tarif</h3>
                    <button
                      className='button--delete'
                      aria-label={`${item.product_category}tarif aus Bestellung entfernen`}
                      onClick={() => {
                        const actualTarifs = storage?.items.filter(tarif => {
                          return !('properties' in tarif);
                        });
                        if (actualTarifs.length <= 1) {
                          deleteToken();
                        } else {
                          removeProducts(item.product_id);
                        }
                      }}
                    >
                      <TrashIcon className='OrderOverview__trash-svg' />
                    </button>
                  </div>
                  Tarif: <span className='font-bold'>Stadtwerke {item.label}</span> <br />
                  Verbrauch:
                  <span className='font-bold'>
                    {' '}
                    {new Intl.NumberFormat('de-DE', {}).format(item.consumption)} kWh
                  </span>{' '}
                  <button
                    className='order-overview__edit-button'
                    onClick={() => {
                      if (props.product_setting.product_name !== item.product_category) {
                        setStartStep(0);
                        setOpenOverview(!openOverview);
                        scrollToTop(item.product_category);
                      } else {
                        props.setStep(0);
                        scrollToTop(item.product_category);
                      }
                    }}
                  >
                    <EditIcon className='order-overview__edit-icon' />
                  </button>
                  <br />
                  Vertragslaufzeit:{' '}
                  <span className='font-bold'>
                    {item.features.filter(x => x.name === 'laufzeit')[0].featureValue.label}
                  </span>
                  <br />
                  {/* <h3>Verbrauchstelle</h3> */}
                  Zählernummer:{' '}
                  <span className='font-bold'>{item.consumption_point.meter_number}</span>{' '}
                  <button
                    className='order-overview__edit-button'
                    onClick={() => {
                      if (props.product_setting.product_name !== item.product_category) {
                        setStartStep(4);
                        setOpenOverview(false);
                        scrollToTop(item.product_category);
                      } else {
                        props.setStep(4);
                        scrollToTop(item.product_category);
                      }
                    }}
                  >
                    <EditIcon className='order-overview__edit-icon' />
                  </button>
                  <br />
                  Zählerstand:{' '}
                  <span className='font-bold'>{item.consumption_point.meter_reading}</span>{' '}
                  <button
                    className='order-overview__edit-button'
                    onClick={() => {
                      if (props.product_setting.product_name !== item.product_category) {
                        setOpenOverview(false);
                        scrollToTop(item.product_category);
                      } else {
                        props.setStep(4);
                        scrollToTop(item.product_category);
                      }
                    }}
                  >
                    <EditIcon className='order-overview__edit-icon' />
                  </button>
                  <br />
                  {item.consumption_point.annual_consumption ? (
                    <>
                      Jahresverbrauch:{' '}
                      <span className='font-bold'>{item.consumption_point.annual_consumption}</span>
                      <br />
                    </>
                  ) : null}
                  Ablesedatum:{' '}
                  <span className='font-bold'>
                    {new Date(item.consumption_point.starts_at).toLocaleDateString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </span>
                  <button
                    className='order-overview__edit-button'
                    onClick={() => {
                      if (props.product_setting.product_name !== item.product_category) {
                        setOpenOverview(false);
                        scrollToTop(item.product_category);
                      } else {
                        props.setStep(4);
                        scrollToTop(item.product_category);
                      }
                    }}
                  >
                    <EditIcon className='order-overview__edit-icon' />
                  </button>
                  <br />
                  <div className='priceBackround'>
                    {item.calculated_price_brutto.amount} {item.calculated_price_brutto.currency} /
                    Monat
                  </div>
                  <br />
                  {storage.items
                    .filter(obj => obj.properties?.belongs_to === item.product_id)
                    .map(product => (
                      <div key={product.product_id}>
                        {!product.isPriceless ? (
                          <>
                            <br />
                            <div>Zusätzlich {product.label}</div>
                            <div className='priceBackround'>
                              {product.calculated_price_brutto.amount}{' '}
                              {product.calculated_price_brutto.currency} / Monat
                            </div>
                          </>
                        ) : null}
                      </div>
                    ))}
                </div>
              ))}
          </div>
          {storage?.items ? (
            <div className='productitem-total__container'>
              <h3>Gesamtbetrag</h3>
              <div className='priceBackround'>
                {storage?.total_price_brutto.amount} {storage?.total_price_brutto.currency} / Monat
              </div>
            </div>
          ) : null}
          <div className='item-container'>
            {storage ? (
              <Accordion
                accordions={storage.items
                  .filter(obj => !!obj.product_category)
                  .map(item => {
                    return {
                      title: `Ihre Tarifdetails ${get_product_name(
                        item.product_category,
                        props.product_setting,
                      )}`,
                      text: (
                        <>
                          <div className='detail'>
                            <ul className='ya'>
                              {item.specifications?.map(text => (
                                <li key={text}>{text}</li>
                              ))}
                            </ul>
                          </div>
                          <div className='detail'>
                            <h4>Arbeispreis pro kWh</h4>
                            <p>
                              {item.price_config.work_price_brutto.amount}{' '}
                              {item.price_config.work_price_brutto.currency}
                            </p>
                          </div>
                          {item.price_config.price_based_on === 'yearly' ? (
                            <div className='detail'>
                              <h4>Grundpreis pro Jahr</h4>
                              <p>
                                {item.price_config.price_brutto.amount}{' '}
                                {item.price_config.price_brutto.currency}
                              </p>
                            </div>
                          ) : (
                            <div className='detail'>
                              <h4>Grundpreis pro Monat</h4>
                              <p>
                                {item.price_config.price_brutto.amount}{' '}
                                {item.price_config.price_brutto.currency}
                              </p>
                            </div>
                          )}
                          <p>
                            Alle Preise inkl. {item.product_category === 'gas' ? '19' : '19'}{' '}
                            {/* Gas vorher bei 7 %  */}% UST
                          </p>
                          <div
                            className='detail-text'
                            dangerouslySetInnerHTML={{ __html: item.details || '' }}
                          />
                          {item.downloads ? (
                            <ul className='downloads'>
                              {item.downloads.map((download, index) => {
                                return (
                                  <li key={download}>
                                    <a
                                      href={`/media/documents/${download}`}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      {download}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : null}
                        </>
                      ),
                    };
                  })}
              />
            ) : null}
          </div>
          <div>
            <div className='productitem-wrapper'>
              <div className='product-item'>
                <div className='order-overview__edit-container'>
                  <h3>Kontaktdaten</h3>
                  <button
                    className='order-overview__edit-button'
                    onClick={() => {
                      props.setStep(4);
                      scrollToTop(props.product_setting.product_name);
                    }}
                  >
                    <EditIcon className='order-overview__edit-icon' />
                  </button>
                </div>
                <div className='item-container'>
                  <div>
                    {storage?.customer.salutation}{' '}
                    {storage?.customer.title ? `${storage?.customer.title} ` : ''}
                    {storage?.customer.first_name} {storage?.customer.last_name}
                  </div>
                  <div>
                    {storage?.customer.address} {storage?.customer.house_number}
                  </div>
                  <div>
                    {storage?.customer.post_code} {storage?.customer.city}
                  </div>
                </div>
                <div className='item-container'>
                  <div>
                    Geburtstag: {birthdate.getDate()}.{birthdate.getMonth() + 1}.
                    {birthdate.getFullYear()}
                  </div>
                  <div>Telefon: {storage?.customer.phone}</div>
                  <div>E-Mail: {storage?.email}</div>
                </div>
              </div>
              {storage?.billing_address?.address ? (
                <div className='product-item'>
                  <div className='item-container'>
                    <div className='order-overview__edit-container'>
                      <h3>
                        {storage?.customer?.address === storage?.billing_address?.address &&
                        storage?.billing_address?.house_number === storage.customer?.house_number &&
                        storage?.customer?.post_code === storage.billing_address?.post_code
                          ? 'Rechnungsadresse'
                          : 'Lieferadresse'}
                      </h3>
                      <button
                        className='order-overview__edit-button'
                        onClick={() => {
                          props.setStep(4);
                          scrollToTop(props.product_setting.product_name);
                        }}
                      >
                        <EditIcon className='order-overview__edit-icon' />
                      </button>
                    </div>
                    <div>
                      {storage?.customer.salutation}{' '}
                      {storage?.customer.title ? `${storage?.customer.title} ` : ''}
                      {storage?.customer.first_name} {storage?.customer.last_name}
                    </div>
                    <div>
                      {storage?.billing_address?.address} {storage?.billing_address?.house_number}
                    </div>
                    <div>
                      {storage?.billing_address?.post_code} {storage?.billing_address?.city}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className='item-container'>
              <div className='order-overview__edit-container'>
                <h3>Zahlungsart</h3>
                <button
                  className='order-overview__edit-button'
                  onClick={() => {
                    props.setStep(5);
                    scrollToTop(props.product_setting.product_name);
                  }}
                >
                  <EditIcon className='order-overview__edit-icon' />
                </button>
              </div>
              {storage?.payment?.type === 'debit' ? (
                <>
                  <div>Zahlung per Lastschrift</div>
                  <div>{storage?.payment.debit_mandate.owner}</div>
                  <div>IBAN: {storage?.payment.debit_mandate.iban}</div>
                </>
              ) : null}
              {storage?.payment?.type === 'bank_transfer' ? (
                <div>Zahlung per Überweisung</div>
              ) : null}
            </div>
            {/* <div className='item-container'>
              <Button onClick={() => props.previousStep()}>Daten bearbeiten</Button>
              </div> */}
            {storage?.items.filter(tarif => {
              return !('properties' in tarif);
            }).length === 1 ? (
              <div className='item-container item-container--space'>
                {props.product_setting.product_name ===
                storage?.items.filter(obj => !!obj.product_category)[0].product_category ? (
                  <>
                    {props.product_setting.sideselling_options &&
                    props.product_setting.sideselling_options[0].sideselling_headline ? (
                      <h3>{props.product_setting.sideselling_options[0].sideselling_headline}</h3>
                    ) : null}
                    {props.product_setting.sideselling_options ? (
                      <RawHtml
                        className='order-overview__sideselling-text'
                        html={props.product_setting.sideselling_options[0].sideselling_text}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {props.product_setting.sideselling_headline ? (
                      <h3>{props.product_setting.sideselling_headline}</h3>
                    ) : null}
                    {props.product_setting.sideselling_text ? (
                      <RawHtml
                        className='order-overview__sideselling-text'
                        html={props.product_setting.sideselling_text}
                      />
                    ) : null}
                  </>
                )}

                <Button
                  onClick={() => {
                    if (
                      props.product_setting.product_name ===
                      storage?.items.filter(obj => !!obj.product_category)[0].product_category
                    ) {
                      setOpenOverview(!openOverview);
                      setReopenSideselling(true);
                      scrollToTop(props.product_setting.product_name);
                    } else {
                      props.setStep(0);
                      scrollToTop(props.product_setting.product_name);
                    }
                  }}
                >
                  {props.product_setting.product_name ===
                  storage?.items.filter(obj => !!obj.product_category)[0].product_category
                    ? `${props.product_setting.sideselling_options?.[0].name}tarif dazu buchen`
                    : `${props.product_setting.name}tarif dazu buchen`}
                </Button>
              </div>
            ) : null}
            <form onSubmit={e => e.preventDefault()}>
              <div className='item-container'>
                <Checkbox
                  id='agb'
                  label='AGB'
                  value=''
                  required
                  checked={agb}
                  onCheckedChange={() => {
                    setAgb(!agb);
                  }}
                  help_text={
                    <span>
                      {storage?.items
                        ?.filter(obj => obj.consumption_point?.meter_number)
                        .map((item, index) => (
                          <span key={item.product_id}>
                            ich akzeptiere die{' '}
                            <Link target='_blank' href={`/media/documents/${item.agb_url}`}>
                              allgemeinen Geschäftsbedingungen
                              {storage?.items?.filter(obj => obj.consumption_point?.meter_number)
                                .length > 1 ? (
                                <>
                                  {' '}
                                  {get_product_name(item.product_category, props.product_setting)}
                                </>
                              ) : null}
                            </Link>{' '}
                            sowie die entsprechenden Ergänzenden Bedingungen
                            {storage.items.length !== index + 1 ? <br /> : null}
                          </span>
                        ))}
                    </span>
                  }
                />
              </div>
              <div className='item-container'>
                <Checkbox
                  id='datenschutz'
                  label='Datenschutz'
                  value=''
                  required
                  help_text={
                    <span>
                      Ich akzeptiere die{' '}
                      <Link target='_blank' href='/datenschutz'>
                        Datenschutzbestimmungen
                      </Link>
                    </span>
                  }
                  checked={dataSecurity}
                  onCheckedChange={() => {
                    setDataSecurity(!dataSecurity);
                  }}
                />
              </div>
              <Button
                primary
                onClick={() => {
                  submitOrder();
                }}
              >
                Verbindlich bestellen
              </Button>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
});

OrderOverview.displayName = 'OrderOverview';

export default OrderOverview;
