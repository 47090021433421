import React from 'react';
import { WagtailSnippetImage } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';

export type ContactType = {
  id: number;
  firstname: string;
  lastname: string;
  position: string;
  telefon: string;
  email: string;
  image: WagtailSnippetImage;
  rendition_url: string;
};

type Props = {
  contact: ContactType;
};

function Contact(props: Props) {
  const { contact } = props;

  return (
    <div className={contact.image ? 'component contact' : 'component contact nogrid'}>
      {contact.image ? (
        <div className='contact__image'>
          <Image
            src={addBaseUrl(contact.rendition_url)}
            alt={contact.image.title}
            width={164}
            height={164}
            priority
          />
        </div>
      ) : null}
      <div className='textwrapper'>
        <div>
          <div className='contact__name'>
            {contact.firstname ? contact.firstname : null}{' '}
            {contact.lastname ? contact.lastname : null}
          </div>
          {contact.position ? <div className='contact__position'>{contact.position}</div> : null}
        </div>
        {contact.telefon ? (
          <div className='contact__phone'>
            <span>Telefon:</span> {contact.telefon}
          </div>
        ) : null}
      </div>
      {contact.email ? (
        <div className='contact__mail'>
          <a href={`mailto:${contact.email}`} className='contact__mail button'>
            E-Mail senden
          </a>
        </div>
      ) : null}
    </div>
  );
}

export default Contact;
