import { Accordion as AccordionType } from '@/types';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import clsx from 'clsx';

import Accordion from './Accordion';

type Props = {
  className?: string;
  accordions: AccordionType[];
  defaultOpen?: boolean;
};

function Accordions(props: Props) {
  const { accordions, className, defaultOpen } = props;

  return (
    <AccordionPrimitive.Root
      className={clsx('flow', className)}
      collapsible
      type='single'
      defaultValue={defaultOpen ? accordions[0].title : undefined}
    >
      {accordions.map(accordion => (
        <Accordion key={accordion.title} title={accordion.title} text={accordion.text} />
      ))}
    </AccordionPrimitive.Root>
  );
}

export default Accordions;
