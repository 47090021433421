/* eslint-disable react/no-array-index-key */
import { WagtailStreamfieldImage, WagtailSvg } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';

type Tiles = {
  iconsvg: WagtailSvg | null;
  iconimage: WagtailStreamfieldImage | null;
  infotext?: string;
  tiletext?: string;
};

type Props = {
  title?: string;
  tiles: Tiles[];
};

function FeatureTiles(props: Props) {
  const { tiles, title } = props;
  return (
    <div className='streamfield featuretiles'>
      <h2>{title}</h2>
      <div className='featuretiles__wrapper'>
        {tiles.map((tile, index) => (
          <div className='featuretile' key={index}>
            <div className='featuretile__bgwrapper'>
              {tile.iconimage ? (
                <div className='card__iconwrapper'>
                  <Image
                    src={addBaseUrl(tile.iconimage.file.src)}
                    alt={tile.iconimage.file.alt}
                    className='featuretile__icon'
                    width='120'
                    height='120'
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ) : null}
              {tile.iconsvg ? (
                <div className='card__iconwrapper'>
                  <Image
                    src={addBaseUrl(tile.iconsvg.file)}
                    alt={tile.iconsvg.title}
                    className='featuretile__icon'
                    width='120'
                    height='120'
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ) : null}
              <div className='featuretile__text'>{tile.infotext ? tile.infotext : null}</div>
            </div>
            <div className='featuretile__text--small'>{tile.tiletext ? tile.tiletext : null}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureTiles;
