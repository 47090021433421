import Accordions from '@components/Accordions';
import Button from '@components/Button';
import { ProductSetting } from '@components/Order/types';
import RawHtml from '@components/RawHtml';
import Router from 'next/router';
import React, { useCallback, useEffect } from 'react';

type Props = {
  product_settings: ProductSetting;
  headline: string | null;
  description: string | null;
};

export function numberWithCommas(x: number, fixed: number | undefined = undefined) {
  const x_string = x.toFixed(fixed !== undefined ? fixed : 2);
  const parts = x_string.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}

function KwhCalculator(props: Props) {
  const [kwh, setKwh] = React.useState<number | undefined>(undefined);
  const [selectedKWH, setSelectedKWH] = React.useState<boolean>(false);

  const submit = useCallback(() => {
    if (props.product_settings.name === 'Strom') {
      Router.push({ pathname: '/tarifauswahl', query: { kwhConsumption: kwh } });
    } else {
      Router.push({ pathname: 'tarifauswahl-gas', query: { kwhConsumption: kwh } });
    }
  }, [kwh, props.product_settings.name]);

  // automatically submit kwh when clicking on select option
  useEffect(() => {
    if (selectedKWH === true) {
      submit();
      setSelectedKWH(false);
    }
  }, [selectedKWH, submit]);

  // 1c8a5a77-f919-4275-925c-3d59d0b626b3
  return (
    <div className='streamfield'>
      {props.headline ? <h2>{props.headline}</h2> : null}
      {props.description ? (
        <RawHtml className='kwh-calc__description' html={props.description} />
      ) : null}
      <div className='Order'>
        <div className='KwhCalculator kwh-calc--streamfield'>
          <div>
            <form
              onSubmit={e => {
                e.preventDefault();
                submit();
              }}
            >
              <div>
                <Accordions
                  defaultOpen
                  accordions={[
                    {
                      title: `${props.product_settings.name}verbrauch kWh / Jahr`,
                      text: (
                        <>
                          <div className='inputwrapper'>
                            <input
                              id='stromverbrauch'
                              required
                              placeholder='kWh / Jahr'
                              value={kwh || ''}
                              min={1}
                              type='number'
                              onChange={event => setKwh(parseInt(event.target.value, 10))}
                            />
                            <Button primary className='next' onClick={() => {}}>
                              <span className='sr-only'>Weiter</span>
                            </Button>
                          </div>
                          {props.product_settings.kwh.map(obj => {
                            return (
                              <div
                                tabIndex={0}
                                key={obj.id}
                                className='option'
                                aria-label='Vordefinierter Verbrauch'
                                onClick={() => {
                                  setSelectedKWH(true);
                                  setKwh(obj.kwh);
                                }}
                                onKeyUp={e => {
                                  if (e.key === 'Enter') {
                                    setSelectedKWH(true);
                                    setKwh(obj.kwh);
                                  }
                                }}
                                role='button'
                              >
                                {numberWithCommas(obj.kwh, 0)} kWh{' '}
                                {obj.unit ? `(${obj.unit})` : null}
                              </div>
                            );
                          })}{' '}
                          {props.product_settings.max_kwh ? (
                            <div
                              className='option'
                              tabIndex={0}
                              aria-label='Vordefinierter Verbrauch'
                              onClick={() => {
                                setSelectedKWH(true);
                                setKwh(props.product_settings.max_kwh);
                              }}
                              onKeyUp={e => {
                                if (e.key === 'Enter') {
                                  setSelectedKWH(true);
                                  setKwh(props.product_settings.max_kwh);
                                }
                              }}
                              role='button'
                            >
                              &gt;={' '}
                              {new Intl.NumberFormat('de-DE', {}).format(
                                props.product_settings.max_kwh,
                              )}{' '}
                              kWh
                            </div>
                          ) : null}
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

KwhCalculator.displayName = 'KwhCalculator';

export default KwhCalculator;
