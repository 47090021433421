import React, { PropsWithChildren } from 'react';
import Head from 'next/head';
import { PageDetailMetaData } from '@/types';
import { Streamfields } from '@/streamfields';

type SeoProps = {
  seo_html_title: string;
  seo_meta_description: string;
  seo_og_title: string;
  seo_og_description: string;
  seo_og_url: string;
  seo_og_image: string;
  seo_og_type: string;
  seo_twitter_title: string;
  seo_twitter_description: string;
  seo_twitter_url: string;
  seo_twitter_image: string;
  seo_meta_robots: string;
  canonicalLink: string;
};

const defaultSeoProps: Partial<SeoProps> = {};

export type Props = {
  meta: PageDetailMetaData;
  title: string;
  seo: Partial<SeoProps>;
  // eslint-disable-next-line react/no-unused-prop-types
  blocksLoaderData: Partial<Record<Streamfields, unknown>>;
};

function BasePage(props: PropsWithChildren<Props>) {
  const { children, seo = defaultSeoProps, title } = props;

  const {
    seo_html_title: seoHtmlTitle,
    seo_meta_description: seoMetaDescription,
    seo_og_title: seoOgTitle,
    seo_og_description: seoOgDescription,
    seo_og_url: seoOgUrl,
    seo_og_image: seoOgImage,
    seo_og_type: seoOgType,
    seo_twitter_title: seoTwitterTitle,
    seo_twitter_description: seoTwitterDescription,
    seo_twitter_url: seoTwitterUrl,
    seo_twitter_image: seoTwitterImage,
    seo_meta_robots: seoMetaRobots,
    canonicalLink,
  } = seo;

  let url = props?.meta?.html_url;
  if (url?.endsWith('/')) {
    url = url.slice(0, (props?.meta?.html_url?.length ?? 0) - 1);
  }

  return (
    <>
      <Head>
        <title>{seoHtmlTitle ?? title}</title>
        <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
        <link rel='manifest' href='/favicon/site.webmanifest' />
        <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color='#134094' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
        {seoMetaDescription ? <meta name='description' content={seoMetaDescription} /> : null}
        {seoOgTitle ? <meta property='og:title' content={seoOgTitle} /> : null}
        {seoOgDescription ? <meta property='og:description' content={seoOgDescription} /> : null}
        {seoOgUrl ? <meta property='og:url' content={seoOgUrl} /> : null}
        {seoOgImage ? <meta property='og:image' content={seoOgImage} /> : null}
        {seoOgType ? <meta property='og:type' content={seoOgType} /> : null}
        {seoTwitterTitle ? <meta property='twitter:title' content={seoTwitterTitle} /> : null}
        {seoTwitterDescription ? (
          <meta property='twitter:description' content={seoTwitterDescription} />
        ) : null}
        {seoTwitterUrl ? <meta property='twitter:url' content={seoTwitterUrl} /> : null}
        {seoTwitterImage ? <meta property='twitter:image' content={seoTwitterImage} /> : null}
        <meta name='robots' content={seoMetaRobots} />
        {canonicalLink || url ? <link rel='canonical' href={canonicalLink ?? url} /> : null}
      </Head>
      <div className='base-page'>{children}</div>
    </>
  );
}

export default BasePage;
