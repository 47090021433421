import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import clsx from 'clsx';
import React from 'react';
import { Streamfields as TStreamfields } from '../index';

type BackgroundColors =
  | 'light-grey'
  | 'light-blue'
  | 'white'
  | 'gradient-light-blue-white'
  | 'gradient-light-blue-dark-blue'
  | 'gradient-dark-green-green';

type Props = {
  full_width: boolean;
  content: Streamfield[];
  background: BackgroundColors;
  loaderData?: Partial<Record<TStreamfields, unknown>>;
};

function Container(props: Props) {
  const { content, background, full_width, loaderData } = props;
  return (
    <div
      className={clsx('streamfield', 'container-field', {
        'container-field--full-width': full_width,
        'container-field--light-grey': background === 'light-grey',
        'container-field--light-blue': background === 'light-blue',
        'container-field--gradient-light-blue-dark-blue':
          background === 'gradient-light-blue-dark-blue',
        'container-field--gradient-light-blue-white': background === 'gradient-light-blue-white',
        'container-field--gradient-dark-green-green': background === 'gradient-dark-green-green',
      })}
    >
      {content ? (
        <div className={clsx({ container: full_width })}>
          <Streamfields fields={content} blocksLoaderData={loaderData} />
        </div>
      ) : null}
    </div>
  );
}

export default Container;
