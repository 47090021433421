import React, { ReactElement } from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import clsx from 'clsx';

type Tab = {
  id: string;
  trigger: string | ReactElement;
  content: string | ReactElement;
};

type Props = {
  items: Tab[];
  containerClassName?: string;
  triggerClassName?: string;
  triggerListClassName?: string;
  contentClassName?: string;
} & TabsPrimitive.TabsProps;

function Tabs(props: Props) {
  const {
    items,
    containerClassName,
    triggerClassName,
    triggerListClassName,
    contentClassName,
    ...rest
  } = props;

  return (
    <TabsPrimitive.Root
      className={clsx('tabs', containerClassName)}
      defaultValue={items?.[0]?.id}
      {...rest}
    >
      <TabsPrimitive.List className={clsx('tabs__list', triggerListClassName)}>
        {items.map(item => (
          <TabsPrimitive.Trigger
            key={item.id}
            className={clsx('tabs__trigger', triggerClassName)}
            value={item.id}
          >
            {item.trigger}
          </TabsPrimitive.Trigger>
        ))}
      </TabsPrimitive.List>
      {items.map(item => (
        <TabsPrimitive.Content
          key={item.id}
          className={clsx('tabs__content', contentClassName)}
          value={item.id}
        >
          {item.content}
        </TabsPrimitive.Content>
      ))}
    </TabsPrimitive.Root>
  );
}

export default Tabs;
