import { Company } from '@streamfields/BonusweltSlider/models';

import '@splidejs/splide/dist/css/splide.min.css';
import Link from 'next/link';

import { addBaseUrl } from '@/utils/url';
import NextImage from 'next/image';

interface Props {
  company: Company;
}

const getSubstr = (text: string) => {
  const TEXT_LENGTH = 100;
  let newText = text?.substring(0, TEXT_LENGTH);
  if (text?.length > TEXT_LENGTH) {
    newText += ' ...';
  }
  return newText;
};

function BonusweltCompany(props: Props) {
  const { company } = props;

  return (
    <div className='bonuswelt_company'>
      <div className='bonuswelt_company__image'>
        {company.offer?.image?.file?.src ? (
          <NextImage
            className='image__element'
            src={addBaseUrl(company.offer.image.file.src)}
            alt={company.offer.image.file.alt}
            width={company.offer.image.file.width}
            height={company.offer.image.file.height}
            sizes='100vw'
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        ) : null}
      </div>
      <div className='bonuswelt_company__container'>
        <div className='bonuswelt_company__logo'>
          <NextImage
            className='image__element'
            src={addBaseUrl(company.logo.file.src)}
            alt={company.logo.file.alt}
            fill
            sizes='100vw'
            style={{
              objectFit: 'contain',
            }}
          />
        </div>
        <div className='bonuswelt_company__name'>
          <h3>{company.name}</h3>
        </div>
        <div className='bonuswelt_company__description'>
          {getSubstr(company.offer?.plain_description)}
        </div>
        <div className='bonuswelt__button'>
          <Link href={`/bonusweltdetail?id=${company.id}`} legacyBehavior>
            <a className='button'>Angebotsdetails</a>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BonusweltCompany;
