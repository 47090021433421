import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import clsx from 'clsx';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import { WagtailPage, WagtailStreamfieldImage } from '@/types';
// import Link from 'next/link';
import RawHtml from '@components/RawHtml';

type Card = {
  button_page: WagtailPage | null;
  button_url: string | null;
  buttonLink?: boolean;
  text: string;
  title: string;
  svg: string;
  image: WagtailStreamfieldImage | null;
};

type Props = {
  cardsButtonPage?: WagtailPage | null;
  cardsButton?: string;
  roundImage?: boolean;
  title?: string;
  text?: string;
  bottomtext?: string;
  background: string;
  cards: Card[];
};

function Cards(props: Props) {
  const { cards, title, text, background, bottomtext, roundImage, cardsButtonPage, cardsButton } =
    props;

  return (
    <div className={clsx('cards streamfield', background)}>
      <div className={clsx(roundImage ? 'roundImg' : '')}>
        {title ? <h2 className='cards__title'>{title}</h2> : null}
        {text ? <RawHtml html={text} /> : null}

        <div className='cards__wrapper'>
          {cards.map(card => (
            <div className='card' key={card.title}>
              {card.image ? (
                <div className='card__iconwrapper'>
                  <Image
                    src={addBaseUrl(card.image.file.src)}
                    alt={card.image.file.alt}
                    className='card__icon'
                    width={roundImage ? '300' : '78'}
                    height={roundImage ? '300' : '78'}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              ) : null}

              {card.svg ? <RawHtml className='card__icon' html={card.svg} /> : null}
              {title ? (
                <h3 className='card__title'>{card.title}</h3>
              ) : (
                <h2 className='card__title'>{card.title}</h2>
              )}

              <RawHtml className='card__text' html={card.text} />
              {card.button_page || card.button_url ? (
                <Link
                  href={
                    card.button_page
                      ? removeBaseUrl(card.button_page.full_url)
                      : card.button_url ?? ''
                  }
                  passHref
                  className={clsx(card.buttonLink ? 'button card__link' : 'card__link')}
                >
                  Mehr erfahren
                </Link>
              ) : null}
            </div>
          ))}
        </div>
        {cardsButtonPage ? (
          <Link
            href={cardsButtonPage ? removeBaseUrl(cardsButtonPage.full_url) : ''}
            passHref
            className='button button--primary button-bottom text-center '
          >
            {cardsButton || ''}
          </Link>
        ) : null}
        {bottomtext ? <RawHtml className='cards__footer container' html={bottomtext} /> : null}
      </div>
    </div>
  );
}

export default Cards;
