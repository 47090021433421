import { WagtailImageRendition } from '@/types';
import React from 'react';
import Image from 'next/image';
import Picture from '@components/Picture/Picture';
import { addBaseUrl } from '@utils/url';

type Props = {
  image: WagtailImageRendition;
  mobileImage?: WagtailImageRendition | null;
};

function BannerImage(props: Props) {
  const { image, mobileImage } = props;

  return (
    <div className='banner-image'>
      {mobileImage ? (
        <Picture
          fill
          image={addBaseUrl(image.url)}
          mobileImage={addBaseUrl(mobileImage.url)}
          common={{ alt: image.alt, width: image.width, height: image.height }}
          sizes='100vw'
        />
      ) : (
        <Image
          src={addBaseUrl(image.url)}
          alt={image.alt}
          width={image.width}
          height={image.height}
        />
      )}
    </div>
  );
}

export default BannerImage;
