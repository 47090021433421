import RawHtml from '@components/RawHtml';
import clsx from 'clsx';
import React from 'react';
import Button from '@components/Button';

type Props = {
  type:
    | 'warning'
    | 'success'
    | 'error'
    | 'information'
    | 'grad-ty'
    | 'grad-tb'
    | 'grad-yl'
    | 'grad-yb';
  title: string;
  text: string;
  displaied_characters: number | null;
};

function Message(props: Props) {
  const { type, title, text, displaied_characters } = props;
  let defaultText = text;
  if (displaied_characters) {
    defaultText = defaultText.split('').splice(26, displaied_characters).join('');
  }
  const [displayText, setDisplayText] = React.useState(defaultText);

  return (
    <div
      className={clsx('streamfield', 'message', 'flow', {
        'message--information': type === 'information',
        'message--error': type === 'error',
        'message--warning': type === 'warning',
        'message--success': type === 'success',
        'message--grad-ty': type === 'grad-ty',
        'message--grad-tb': type === 'grad-tb',
        'message--grad-yl': type === 'grad-yl',
        'message--grad-yb': type === 'grad-yb',
      })}
    >
      <h2 className='message__title'>{title}</h2>
      <RawHtml html={displayText} />
      {displaied_characters && text !== displayText ? (
        <Button className='message__button' onClick={() => setDisplayText(text)}>
          Mehr erfahren
        </Button>
      ) : null}
    </div>
  );
}

export default Message;
