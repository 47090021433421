import IconCheck from '@images/progress_check.svg';
import IconArrow from '@images/progress_this.svg';
import IconArrowWhite from '@images/progress_this_white.svg';
import React from 'react';

type Props = {
  steps: number[];
  currentStep: number;
  hideSteps?: number[];
  stepNames?: string[];
  firstIcon?: any;
};

const ProcessBar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const range = [];
  for (let i = 0; i < props.steps.length; i += 1) {
    range.push(i);
  }
  if (props?.hideSteps?.includes(props.currentStep)) return <div ref={ref} />;
  return props.firstIcon && props.currentStep === 0 ? null : (
    <div className='ProcessBar' ref={ref}>
      <div className='linebetween'>
        <div className='inline-steps'>
          {range.map(index => {
            if (props?.hideSteps?.includes(props.steps[index])) return;
            return (
              <div key={index} className='icon-container'>
                {props.firstIcon && index === 0 ? (
                  <div className='icon' key={index}>
                    <props.firstIcon />
                  </div>
                ) : (
                  // <div className='icon-container-item'>
                  <>
                    <div className='icon' key={index}>
                      {index > props.currentStep ? <IconArrowWhite className='next' /> : null}
                      {index === props.currentStep ? <IconArrow /> : null}
                      {index < props.currentStep ? <IconCheck /> : null}
                    </div>
                    <div className='label'>{props.stepNames ? props.stepNames?.[index] : null}</div>
                  </>
                  // </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

ProcessBar.displayName = 'ProcessBar';

export default ProcessBar;
