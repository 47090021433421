import React from 'react';
import clsx from 'clsx';
import { WagtailSvg } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';
import { ProductSetting } from '@components/Order/types';
import ProductOptions, { ProductOptionType } from './ProductOptions';

export type OrderIntro = {
  order_intro: {
    description: string;
    headline: string;
    product_settings: ProductSetting;
  };
};

export type ProductOverviewType = {
  title: string;
  svg: WagtailSvg;
  options: ProductOptionType[];
  text: string;
  order?: OrderIntro[];
};

type Props = {
  overview: ProductOverviewType[];
};

function ProductOverview(props: Props) {
  const { overview } = props;

  const [selected, setSelected] = React.useState(0);

  return (
    <div className='product-overview component'>
      {overview.map((o, index) => {
        return (
          <React.Fragment key={o.title}>
            <div
              className={clsx('product-overview__product', index === selected ? 'active' : null)}
              role='button'
              tabIndex={index}
              onClick={() => {
                setSelected(index);
              }}
              onKeyDown={() => {
                setSelected(index);
              }}
            >
              <div className='product-overview__kachel-title'>
                <h3>{o.title}</h3>
              </div>
              <div className='product-overview__kachel-svg'>
                <Image src={addBaseUrl(o.svg.file)} alt={o.svg.title} width={81} height={81} />
              </div>
            </div>
            {index === selected ? (
              <ProductOptions
                options={overview[selected].options}
                text={overview[selected].text}
                card={selected}
                orderIntro={overview[selected].order?.[0]?.order_intro}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default ProductOverview;
