import React from 'react';
import Image from 'next/image';
import type { WagtailStreamfieldImage } from '@/types';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';

export type MagazinType = {
  id: number;
  title: string;
  subtitle: string;
  subheader: string;
  banner_image: WagtailStreamfieldImage | null;
  url_path: string;
  full_url: string;
};

type Props = {
  magazin: MagazinType;
};

function Magazin(props: Props) {
  const { magazin } = props;
  return (
    <div className='magazin component'>
      <div className='magazin__image'>
        {magazin.banner_image ? (
          <Image
            src={addBaseUrl(magazin.banner_image.file.src)}
            alt={magazin.banner_image.file.alt}
            fill
            sizes='100vw'
          />
        ) : null}
      </div>
      <div className='magazin__title'>{magazin.title}</div>
      <div className='magazin__text'>
        {magazin.subtitle ? <RawHtml html={magazin.subtitle} /> : null}
      </div>
    </div>
  );
}

export default Magazin;
