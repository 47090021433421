/* eslint-disable react/no-array-index-key */
import React from 'react';
import Richtext from '@streamfields/Richtext';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Link from 'next/link';
import { WagtailPage } from '@/types';
import { removeBaseUrl } from '@/utils/url';

import BonusweltCompany from '@components/BonusweltCompany';
import { Company } from './models';

interface Props {
  title: string;
  description: string;
  overview_page: WagtailPage;
  companies: Array<Company>;
}

function BonusweltSlider(props: Props) {
  const { companies } = props;

  return (
    <div className='bonuswelt'>
      <div className='bonuswelt__before_slide'>
        <h2 className='bonuswelt__title'>{props.title}</h2>
        <div className='bonuswelt__description'>
          <Richtext sourcecode={props.description} />
        </div>
      </div>
      <div className='bonuswelt__splide'>
        <Splide
          options={{
            mediaQuery: 'min',
            rewind: true,
            gap: '20px',
            arrowPath: 'm 13.000027,8 14,11.994 -14,12.006',
            perPage: 1,
            arrows: false,
            padding: { left: 0, right: 50 },
            breakpoints: {
              640: {
                perPage: 2,
                padding: '0',
              },
              820: {
                arrows: companies.length > 2,
              },
              1200: {
                perPage: 3,
                arrows: companies.length > 3,
              },
            },
          }}
        >
          {companies.map((company, index) => (
            <SplideSlide key={index}>
              <BonusweltCompany company={company} />
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className='bonuswelt__after_slide'>
        {props.overview_page ? (
          <div className='bonuswelt__button_uebersicht'>
            <Link href={removeBaseUrl(props.overview_page.full_url)} legacyBehavior>
              <a className='button button--primary'>Zur Angebotsübersicht</a>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BonusweltSlider;
