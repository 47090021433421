/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Button from '@components/Button';
import { WagtailPage } from '@/types';
import { removeBaseUrl } from '@/utils/url';
import Richtext from '@streamfields/Richtext';
import Link from 'next/link';
import Magazin from '../../components/Magazin';
import { MagazinType } from '../../components/Magazin/Magazin';

interface Props {
  title: string;
  description: string;
  overview_page: WagtailPage;
  magazins: Array<MagazinType>;
}

function MagazinSlider(props: Props) {
  const { magazins } = props;
  return (
    <div className='streamfield magazin-slider'>
      <h2>{props.title}</h2>
      <Richtext sourcecode={props.description} />
      <div className='magazin-slider__splide'>
        <Splide
          options={{
            mediaQuery: 'min',
            rewind: true,
            gap: '20px',
            perPage: 1,
            arrowPath: 'm 13.000027,8 14,11.994 -14,12.006',
            arrows: false,
            padding: { left: 0, right: 50 },
            breakpoints: {
              640: {
                perPage: 2,
                padding: '0',
              },
              820: {
                arrows: magazins.length > 2,
              },
              1200: {
                perPage: 3,
                arrows: magazins.length > 3,
              },
            },
          }}
        >
          {magazins.map((magazin, index) => (
            <SplideSlide key={index}>
              <Magazin magazin={magazin} />
              <Link href={magazin.full_url}>Weiterlesen</Link>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      {props?.overview_page?.full_url ? (
        <div className='button-row'>
          <Link href={removeBaseUrl(props.overview_page.full_url)} legacyBehavior>
            <Button primary>Zu allen Artikeln</Button>
          </Link>
        </div>
      ) : null}
    </div>
  );
}

export default MagazinSlider;
