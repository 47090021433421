import RawHtml from '@components/RawHtml';
import React from 'react';

type Props = {
  value?: string;
  sourcecode?: string;
};

function Stellen(props: Props) {
  const { value, sourcecode } = props;
  return <RawHtml className='streamfield richtext stellen' html={value ?? sourcecode} />;
}

export default Stellen;
