import NewOrder from '@components/Order';
import { ProductSetting } from '@components/Order/types';

type Props = {
  product_settings: ProductSetting;
  show_svg: boolean;
  product_id: number;
};

function Order(props: Props) {
  return (
    <div className='streamfield order'>
      <NewOrder
        show_svg={props.show_svg}
        product_setting={props.product_settings}
        product_id={props.product_id}
      />
    </div>
  );
}

export default Order;
