import Button from '@components/Button';
import { Checkbox } from '@components/Form';
import Input from '@components/Form/Input';
import RadioButton from '@components/RadioButton';
import React, { useState } from 'react';
import { useStorage } from '../Provider/StorageProvider';
import { useToken } from '../Provider/TokenProvider';
import { endpoints, getUrl, headers } from '../hooks/api';
import { ProductSetting } from '../types';
// import { ItemTariff } from '../types';

type Props = {
  previousStep: () => void;
  nextStep: () => void;
  product_setting: ProductSetting;
  // currentProduct?: ItemTariff | undefined;
};

// type OrderOption = { value: 'neueinzug'; text: string } | { value: 'bestandskunde'; text: string };

const PaymentForm = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { storage, setStorage } = useStorage();
  const { token } = useToken();
  const [ermaechtigung, setErmaechtigung] = useState(false);
  const [error, setError] = useState<any>();
  const [paymentType, setPaymentType] = useState<'debit' | 'account' | 'bank_transfer'>(
    storage?.payment?.type || 'debit',
  );

  const updateUrl = token ? getUrl(token, endpoints.details) : '';

  // https://stackoverflow.com/questions/21928083/iban-validation-check
  function mod97(digital: number | string) {
    const digi = digital.toString();
    let checksum: number | string = digi.slice(0, 2);
    let fragment = '';
    for (let offset = 2; offset < digi.length; offset += 7) {
      fragment = checksum + digi.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

  const validateIban = (input: string) => {
    const codeLengths = {
      DE: 22,
    };
    const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, '');
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    if (!code || iban.length !== (codeLengths as any)[code[1]]) {
      return false;
    }
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
      return (letter.charCodeAt(0) - 55).toString();
    });
    return mod97(digits) === 1;
  };

  const handleSubmit = (event: any) => {
    setError(undefined);
    event.preventDefault();
    if (!ermaechtigung && paymentType === 'debit') return;
    // load form data
    const data: Record<string, string> = {};
    for (let i = 0; i < event.target.elements.length; i += 1) {
      const element = event.target.elements[i];
      if (element.dataset?.isvalid === 'false') {
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        return;
      }
      switch (element.type) {
        case 'button' || 'submit':
          break;
        case 'checkbox':
          data[element.name] = element.checked;
          break;
        default:
          data[element.name] = element.value;
          break;
      }
    }

    fetch(updateUrl, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        checkout: {
          payment: {
            type: paymentType,
            ...(paymentType === 'debit'
              ? {
                  debit_mandate: {
                    iban: data.iban,
                    owner: data.kontoinhaber,
                  },
                }
              : {}),
          },
        },
      }),
    }).then(padchedRes => {
      if (padchedRes.status >= 300) {
        padchedRes.json().then(newError => {
          setError(newError);
        });
        return;
      }

      if (storage) {
        padchedRes.json().then(newdata => {
          setStorage({ ...storage, ...newdata });
        });
        // setStorage({ ...storage, ...padchedRes.json() });
      }
      props.nextStep();
    });
  };

  return (
    <div className='PaymentForm' ref={ref}>
      <form onSubmit={async e => handleSubmit(e)}>
        <h2>Zahlungsart - Ihr {props.product_setting.name}vertrag</h2>

        <div
          onClick={() => setPaymentType('debit')}
          onKeyUp={() => setPaymentType('debit')}
          role='presentation'
          className='customer-type'
        >
          <RadioButton
            checked={paymentType === 'debit'}
            // items={[
            //   { label: 'Neueinzug', value: 'neueinzug' },
            //   { label: 'Bestandskunde', value: 'bestandskunde' },
            // ]}
          />
          <span>Lastschrift</span>
        </div>
        <div
          onClick={() => setPaymentType('bank_transfer')}
          onKeyUp={() => setPaymentType('bank_transfer')}
          role='presentation'
        >
          <RadioButton checked={paymentType === 'bank_transfer'} />
          <span>per Überweisung</span>
        </div>
        {paymentType === 'debit' ? (
          <>
            <div className='InputRow'>
              <Input
                label='Kontoinhaber'
                placeholder=''
                id='kontoinhaber'
                required
                defaultValue={storage?.payment?.debit_mandate?.owner || ''}
                errorText={error?.checkout?.payment?.debit_mandate?.owner?.[0]}
              />
            </div>
            <div className='InputRow'>
              <Input
                label='IBAN'
                placeholder=''
                id='iban'
                required
                defaultValue={storage?.payment?.debit_mandate?.iban || ''}
                validation={validateIban}
                errorText={error?.checkout?.payment?.debit_mandate?.iban?.[0]}
              />
            </div>

            <div className='checkbox-row'>
              <Checkbox
                id='ermaechtigung'
                label='Ich ermächtige die Stadtwerke Witten GmbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen.'
                value=''
                checked={ermaechtigung}
                onCheckedChange={() => setErmaechtigung(!ermaechtigung)}
                required
              />
            </div>
          </>
        ) : null}

        <p className='navigation-row'>
          <Button className='prev' onClick={() => props.previousStep()}>
            Zurück
          </Button>
          <Button className='next' primary onClick={() => {}}>
            Weiter
          </Button>
        </p>
      </form>
    </div>
  );
});

PaymentForm.displayName = 'PaymentForm';

export default PaymentForm;
