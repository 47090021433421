import { WagtailPage } from '@/types';
import { removeBaseUrl } from '@/utils/url';
import Link from 'next/link';
// import Link from 'next/link';
import RawHtml from '@components/RawHtml';

type Price = {
  title?: string;
  subtitle?: string;
  text: string;
  button_page: WagtailPage | null;
};

type Props = {
  blocktitle?: string;
  pricesButtonPage?: WagtailPage | null;
  pricesButton?: string;
  bottomtext?: string;
  price: Price[];
};

function Prices(props: Props) {
  const { price: prices, blocktitle, pricesButtonPage, pricesButton, bottomtext } = props;
  return (
    <div className='prices cards streamfield'>
      {blocktitle ? <h2>{blocktitle}</h2> : ''}
      <div className='cards__wrapper'>
        {prices.map(price => (
          <div className='card price' key={price.title}>
            {price.title ? <div className='h3 price__title'>{price.title}</div> : ''}
            {price.subtitle ? <div className='price__subtitle'>{price.subtitle}</div> : ''}
            {price.text ? <RawHtml className='price__text' html={price.text} /> : ''}

            {price.button_page ? (
              <Link
                href={price.button_page ? removeBaseUrl(price.button_page.full_url) : ''}
                passHref
                className='price__link'
              >
                Tarifdetails
              </Link>
            ) : null}
          </div>
        ))}
      </div>
      {pricesButtonPage?.full_url ? (
        <div className='prices__button'>
          <Link
            href={pricesButtonPage ? removeBaseUrl(pricesButtonPage.full_url) : ''}
            passHref
            className='button card__link'
          >
            {pricesButton || 'mehr'}
          </Link>
        </div>
      ) : null}
      {bottomtext ? <RawHtml className='prices__bottomText' html={bottomtext} /> : ''}
    </div>
  );
}

export default Prices;
